import React, { useEffect, useState } from "react";
import styles from "./FileUploadItem.module.css";

import Close from "../../assets/icon/close-30.png";
import Restart from "../../assets/icon/restart-24.png";
import LinearProgress from "@mui/material/LinearProgress";

import { orderApiV2 } from "../../Api";
import { OrderConstant } from "../../Constants";

const FileUploadItem = (props) => {

    const { uploaditem } = props

    const filesizeKB = (uploaditem.size / 1000).toFixed(2);

    /**
     * Status:
     * - start: Signal react to start uploading
     * - 
     */

    const [progress, setProgress] = useState(0);
    const [status, setStatus] = useState('start')
    const [statusText, setStatusText] = useState('');
    const [statusColor, setStatusColor] = useState('black');
    const [progressVariant, setProgressVariant] = useState('determinate');
    const [exceedSizeLimit, setExceedSizeLimit] = useState(false);

    const [actionIcon, setActionIcon] = useState(Close);

    const config = {
        onUploadProgress: function (progressEvent) {
            var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)

            setStatusText(`Uploading ${percentCompleted}%`);
            setProgress(percentCompleted);

            if (percentCompleted === 100) {
                setStatusText(`Processing..`);
                setProgressVariant('indeterminate');
            }
        },
        signal: uploaditem.axiosController.signal
    }

    useEffect(() => {
        const maxSize = OrderConstant.MaxSizePerImage;

        if (uploaditem.size > maxSize) {
            setStatus('error');
            setStatusText(`Only image of ${(maxSize / 1024 / 1024).toFixed(1)} MB and below is allowed`);
            setExceedSizeLimit(true);
        } else {
            setStatus('uploading');
        }

        //eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (status === 'uploading') {
            uploadHandler();
        } else {
            setProgressVariant('determinate');
        }

        switch (status) {
            case 'uploading':
                setStatusColor('#4472C4');
                setActionIcon(Close);
                break;
            case 'uploaded':
                setStatusColor('#70AD47');
                setStatusText(`Upload success`);
                break;
            case 'error':
                setStatusColor('red');
                setActionIcon(Restart);
                break;
            default:
                setActionIcon(null);
        }
        //eslint-disable-next-line
    }, [status])

    const uploadHandler = () => {
        orderApiV2.post(`/${uploaditem.orderid}/image`, {
            productId: uploaditem.productid,
            data: uploaditem.imageUri,
            public_id: uploaditem.name
        }, config).then(response => {
            setStatus('uploaded')

            props.onImageUploaded(response.data)
        }).catch(err => {
            setStatus('error');
            setStatusText(err.response?.data ?? err.message)
        })
    }

    const actioner = () => {
        switch (status) {
            case 'uploading':
                props.onUploadCancel(uploaditem);
                break;
            case 'error':
                setStatus('uploading')
                break;
            default:
        }
    }

    return (
        <div className={styles.wrapper}>
            <img src={uploaditem.imageUri} alt="" />
            <div>
                <span
                    style={{ overflow: "hidden", whiteSpace: "nowrap", width: "95%" }}
                    className={styles["img-name"]}>
                    {uploaditem.name}
                </span>
                <span className={styles["img-size"]}>{filesizeKB} KB</span>

                <div style={{ flex: "1 1 auto" }}></div>

                <div style={{ display: "flex", gap: "8px" }}>
                    <div className={styles["progress-wrapper"]}>
                        <LinearProgress variant={progressVariant} value={progress} />
                        <span
                            className={styles["upload-status"]}
                            style={{ color: statusColor }}>
                            {statusText}
                        </span>
                    </div>
                    {
                        !exceedSizeLimit && status !== 'uploaded' &&
                        <img src={actionIcon} width="20" height="20" alt="" onClick={actioner} style={{ cursor: 'pointer' }} />
                    }
                </div>
            </div>
        </div>
    );
};

export default FileUploadItem;
