import React, { useState } from "react";
import dayjs from "dayjs";

import styles from "./ProductItem.module.css";

import TextField from "@mui/material/TextField";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Button from "@mui/material/Button";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import { Close } from "@mui/icons-material";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';

import { orderApiV2 } from "../../../../Api";

import { useDispatch, useSelector } from "react-redux";
import { deleteProduct, onImageDelete } from "../../../../store/order-actions";

import ManageUpload from "../../../../UI/FileInput/ManageUpload";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const inputStyle = {
  "& .MuiInputBase-root": {
    height: 30,
  },
  "& .MuiOutlinedInput-root.MuiInputBase-sizeSmall": {},
  "& .MuiOutlinedInput-input": {
    height: 30,
    padding: "0 8px",
  },
  flex: "0 0 auto",
};

const maxImageLimitPerProduct = 3;

const ProductItem = ({ product, index, onInputChange, formheader }) => {
  const dispatch = useDispatch();

  const order = useSelector(state => state.order.order);

  const [showUploadModal, setShowUploadModal] = useState(false);
  const [openErr, setOpenErr] = useState(false);
  const [snackMsg, setSnackMsg] = useState('false');

  const [cldImagesDeletingInProgress, setCldImagesDeletingInProgress] = useState([])

  const productUploaded = product.uploaded === undefined || (product.uploaded !== undefined && product.uploaded)

  const handleOnCloseModal = () => {
    setShowUploadModal(false);
  }

  const handleOnImageClicked = () => {
    setShowUploadModal(true);
  };

  const cldImageIsDeleting = (cldimage) => {
    return cldImagesDeletingInProgress.includes(cldimage.asset_id)
  }

  const addCldImageToProgress = (cldimage) => {
    setCldImagesDeletingInProgress([...cldImagesDeletingInProgress, cldimage.asset_id])
  }

  const removeCldImageToProgress = (cldimage) => {
    const updated = cldImagesDeletingInProgress.filter(c => c !== cldimage.asset_id);
    setCldImagesDeletingInProgress(updated)
  }

  const handleOnCldImageDelete = (cldimage) => {

    addCldImageToProgress(cldimage);

    orderApiV2.delete(`/${order._id}/image`, {
      data: {
        productId: product.id,
        public_id: cldimage.public_id
      }
    }).then(response => {
      const result = response.data?.result ?? 'Unknown'

      removeCldImageToProgress(cldimage);

      if (result !== 'ok') {
        setOpenErr(true)
        setSnackMsg('Error deleting image, please contact developer')
      } else {
        dispatch(onImageDelete(product.id, cldimage.asset_id));
      }
    }).catch(err => {
      setOpenErr(true)
      setSnackMsg(err.response?.data ?? err.message);
      removeCldImageToProgress(cldimage);
    })
  }

  const deleteProductHandler = () => {
    dispatch(deleteProduct(product.id));
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenErr(false);
  };

  return (
    <Accordion elevation={0} defaultExpanded={true}>
      {showUploadModal && <ManageUpload productId={product.id} product={product} onClose={handleOnCloseModal} />}
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="product-content"
        id="product-header"
        sx={{ padding: 0 }}
      >
        #{index + 1} {product.name === "" ? "" : `- ${product.name}`}
      </AccordionSummary>

      <AccordionDetails sx={{ padding: 0 }}>
        {
          !productUploaded &&
          <div className={styles["input-group"]}>
            <span style={{ color: 'red' }}>*Product is new, please save the order before uploading images</span>
          </div>
        }
        <div className={styles["input-group"]}>
          Item
          <TextField
            id="productname"
            variant="outlined"
            size="small"
            name="name"
            // disabled={formheader.xeroInvoiceId !== ''}
            sx={inputStyle}
            value={product.name}
            onChange={(e) => onInputChange(e, index)}
          />
        </div>

        <div className={styles["input-row"]}>
          <div className={styles["input-group"]}>
            Quantity
            <TextField
              id="quantity"
              variant="outlined"
              size="small"
              sx={inputStyle}
              type="number"
              name="qty"
              disabled={formheader.xeroInvoiceId !== ""}
              value={product.qty}
              onChange={(e) => onInputChange(e, index)}
            />
          </div>
          <div className={styles["input-group"]}>
            Unit Cost
            <OutlinedInput
              sx={inputStyle}
              type="number"
              id="unitcost"
              name="unitCost"
              startAdornment={
                <InputAdornment position="start">$</InputAdornment>
              }
              value={product.unitCost}
              disabled={formheader.xeroInvoiceId !== ""}
              onChange={(e) => onInputChange(e, index)}
            />
          </div>
          <div
            className={styles["input-group"]}
            style={{ backgroundColor: "yellow" }}
          >
            Total Amount (before 9% GST)
            <OutlinedInput
              disabled
              sx={inputStyle}
              id="totalamt"
              type="number"
              value={product.totalAmt?.toFixed(2) ?? ""}
              startAdornment={
                <InputAdornment position="start">$</InputAdornment>
              }
            />
          </div>
        </div>

        {/* <div className={styles['input-row']}>
                    <div className={styles['input-group']}>
                        Delivery fee
                        <OutlinedInput
                            sx={inputStyle}
                            id="deliveryfee"
                            name='deliveryFee'
                            type="number"
                            startAdornment={<InputAdornment position="start">$</InputAdornment>}
                            value={product.deliveryFee}
                            onChange={(e) => onInputChange(e,index)} />
                    </div>
                    <div className={styles['input-group']}>
                        Packaging
                        <OutlinedInput
                            sx={inputStyle}
                            type="number"
                            id="packaging"
                            name='packaging'
                            startAdornment={<InputAdornment position="start">$</InputAdornment>}
                            value={product.packaging}
                            onChange={(e) => onInputChange(e,index)} />
                    </div>
                </div> */}

        <div className={styles["input-group"]}>
          Specification
          <TextField
            id="specification"
            name="specification"
            variant="outlined"
            size="small"
            sx={inputStyle}
            value={product.specification}
            onChange={(e) => onInputChange(e, index)}
          />
        </div>

        <div className={styles["input-group"]}>
          Printing
          <TextField
            id="printing"
            name="printing"
            variant="outlined"
            size="small"
            sx={inputStyle}
            value={product.printing}
            onChange={(e) => onInputChange(e, index)}
          />
        </div>

        <div className={styles["input-group"]}>
          Packaging instruction if any
          <TextField
            id="packagingInstruction"
            name="packagingInstruction"
            variant="outlined"
            size="small"
            sx={inputStyle}
            value={product.packagingInstruction}
            onChange={(e) => onInputChange(e, index)}
          />
        </div>

        <div className={styles["input-row"]}>
          <Tooltip
            describeChild
            title="If uncheck, this product will not be considered as a product and not be shown in supplier info and in-house PDF."
            sx={{ alignSelf: "center" }}
            placement="right"
          >
            <FormControlLabel
              control={<Checkbox
                size="small"
                name="exportable"
                onChange={(e) => onInputChange(e, index)}
                checked={product.exportable}
              />}
              label="Supplier Info"
            />
          </Tooltip>
        </div>

        <div className={styles["input-row"]}>
          <Button
            variant="contained"
            color="success"
            size="small"
            sx={{ width: "150px" }}
            onClick={handleOnImageClicked}
            disabled={product.cld_images.length >= maxImageLimitPerProduct || !productUploaded}
          >
            Manage Upload
          </Button>
          <div style={{ flex: "1 1 auto" }}></div>
          <Button
            variant="outlined"
            color="error"
            size="small"
            sx={{ width: "150px" }}
            disabled={formheader.xeroInvoiceId !== ""}
            onClick={deleteProductHandler}
          >
            Delete
          </Button>
        </div>

        <div className={styles["img-container"]}>
          {product.cld_images.map((img, index) => {

            const urlsplit = img.url.split('/')

            const filename = urlsplit[urlsplit.length - 1];
            const fileSizeInKB = (img.bytes / 1000).toFixed(2)

            return (
              <div key={img.asset_id}>
                <div style={{ height: 'auto', display: 'flex', backgroundColor: 'rgb(242, 242, 242)', justifyContent: 'flex-end', alignItems: 'center' }}>
                  {
                    cldImageIsDeleting(img) &&
                    <CircularProgress size="1.4rem" thickness="7" sx={{ marginLeft: '16px' }} />
                  }

                  <div style={{ flex: '1 1 auto' }}></div>
                  <IconButton aria-label="close" onClick={() => handleOnCldImageDelete(img)} disabled={cldImageIsDeleting(img)}>
                    <Close />
                  </IconButton>
                </div>
                <img style={{ width: '100%' }} src={img.url} alt={filename} />
                <section className={styles['img-descriptor']}>
                  <span className={styles['img-name']}>
                    {filename}
                  </span>
                  <div style={{ display: 'flex' }}>
                    <span style={{ flex: '1 1 auto' }}>{fileSizeInKB} KB</span>
                    <span style={{ color: '#aaa' }}>{dayjs(img.created_at).format('DD/MM/YY HH:mm a')}</span>
                  </div>
                </section>
              </div>
            );
          })}
        </div>
        <Snackbar
          open={openErr}
          autoHideDuration={3000}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          onClose={handleClose}>
          <Alert severity="error">
            {snackMsg}
          </Alert>
        </Snackbar>
      </AccordionDetails>
    </Accordion>
  );
};

export default ProductItem;
