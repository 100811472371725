import React from 'react';
import LinearProgress from '@mui/material/LinearProgress';

import styles from './Loading.module.css';

const Loading = (props) => {

  const loadingText = props.loadingText ?? 'Loading';

  return (
    <div className={`${props.className} ${styles.main}`} style={props.style}>
        {loadingText}
        <LinearProgress sx={{width: '100%'}}/>
    </div>
  )
}

export default Loading