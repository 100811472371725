import React, { memo, useState, useRef, useEffect, useCallback } from 'react';
import { orderApiV2 } from '../../../Api';

import Select from '@mui/material/Select';

import { Paper, Box, Popper } from '@mui/material';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import { DataGrid, GridToolbar, useGridApiContext } from '@mui/x-data-grid';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { useDispatch, useSelector } from 'react-redux';
import { orderActions } from '../../../store/order-slice';

const getPaymentStatusStyle = (status) => {
    const style = {
        whiteSpace: 'nowrap',
        borderRadius: '3px',
        padding: '0 4px',
        textAlign: 'center',
        verticalAlign: 'middle',
        fontSize: '0.8125rem',
        lineHeight: '1.25rem',
    }

    switch (status) {
        case 'Awaiting Payment':
            style.backgroundColor = '#a6d3bb';
            style.boxShadow = 'inset 0 0 0 1px #80c19e';
            style.color = '#002e15';
            break;
        case 'Partial Payment':
            style.backgroundColor = '#FFE699';
            style.boxShadow = 'inset 0 0 0 1px #BF8F00';
            style.color = '#BF8F00';
            break;
        case 'To Invoice':
            style.backgroundColor = '#DEEBF7';
            style.boxShadow = 'inset 0 0 0 1px #4472A9';
            style.color = '#4472A9';
            break;
        case 'Overdue':
            style.backgroundColor = '#FFCBCB';
            style.boxShadow = 'inset 0 0 0 1px #FF2F00';
            style.color = '#FF2F00';
            break;
        default:
            style.backgroundColor = '#fff';
            style.boxShadow = 'inset 0 0 0 1px rgba(0,10,30,0.5)';
            style.color = 'rgba(0,10,30,0.75)';
    }
    return style;
}

function isOverflown(element) {
    return (
        element.scrollHeight > element.clientHeight ||
        element.scrollWidth > element.clientWidth
    );
}

const GridCellExpand = memo(function GridCellExpand(props) {
    const { width, value } = props;
    const wrapper = useRef(null);
    const cellDiv = useRef(null);
    const cellValue = useRef(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const [showFullCell, setShowFullCell] = useState(false);
    const [showPopper, setShowPopper] = useState(false);

    const handleMouseEnter = () => {
        const isCurrentlyOverflown = isOverflown(cellValue.current);
        setShowPopper(isCurrentlyOverflown);
        setAnchorEl(cellDiv.current);
        setShowFullCell(true);
    };

    const handleMouseLeave = () => {
        setShowFullCell(false);
    };

    useEffect(() => {
        if (!showFullCell) {
            return undefined;
        }

        function handleKeyDown(nativeEvent) {
            // IE11, Edge (prior to using Bink?) use 'Esc'
            if (nativeEvent.key === 'Escape' || nativeEvent.key === 'Esc') {
                setShowFullCell(false);
            }
        }

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [setShowFullCell, showFullCell]);

    return (
        <Box
            ref={wrapper}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            sx={{
                alignItems: 'center',
                lineHeight: '24px',
                width: '100%',
                height: '100%',
                position: 'relative',
                display: 'flex',
            }}
        >
            <Box
                ref={cellDiv}
                sx={{
                    height: '100%',
                    width,
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                }}
            />
            <Box
                ref={cellValue}
                sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
            >
                {value}
            </Box>
            {showPopper && (
                <Popper
                    open={showFullCell && anchorEl !== null}
                    anchorEl={anchorEl}
                    style={{ width }}
                >
                    <Paper
                        elevation={1}
                        style={{ minHeight: wrapper.current.offsetHeight - 3 }}
                    >
                        <Typography variant="body2" style={{ padding: 8 }}>
                            {value}
                        </Typography>
                    </Paper>
                </Popper>
            )}
        </Box>
    );
});

GridCellExpand.propTypes = {
    value: PropTypes.string.isRequired,
    width: PropTypes.number.isRequired,
};

function renderCellExpand(params) {
    return (
        <GridCellExpand value={params.value || ''} width={params.colDef.computedWidth} />
    );
}

renderCellExpand.propTypes = {
    /**
     * The column of the row that the current cell belongs to.
     */
    colDef: PropTypes.object.isRequired,
    /**
     * The cell value.
     * If the column has `valueGetter`, use `params.row` to directly access the fields.
     */
    value: PropTypes.string,
};

function SelectEditInputCell(props) {
    const { id, value, field } = props;
    const apiRef = useGridApiContext();

    const handleChange = async (event) => {
        await apiRef.current.setEditCellValue({ id, field, value: event.target.value });
        apiRef.current.stopCellEditMode({ id, field });
    };

    return (
        <Select
            value={value}
            onChange={handleChange}
            size="small"
            sx={{ height: 1 }}
            native
        //autoFocus
        >
            <option>In Production</option>
            <option>Ready to Deliver</option>
            <option>Scheduled</option>
            <option>Delivered</option>
        </Select>
    );
}

SelectEditInputCell.propTypes = {
    /**
     * The column field of the cell that triggered the event.
     */
    field: PropTypes.string.isRequired,
    /**
     * The grid row id.
     */
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    /**
     * The cell value.
     * If the column has `valueGetter`, use `params.row` to directly access the fields.
     */
    value: PropTypes.any,
};

const renderSelectEditInputCell = (params) => {
    return <SelectEditInputCell {...params} />;
};

//to be use in valueFormatter in column
// const gridDateFormatter = (params) => {
//     if (params.value == null) {
//         return '';
//     }

//     return dayjs(params.value).format('DD/MM/YYYY')
// }

const useDeliveryUpload = () => {

    const dispatch = useDispatch();

    return useCallback(
        (delivery) =>
            new Promise((resolve, reject) => {
                orderApiV2.post(`/${delivery.id}`, {
                    delivery: {
                        date: delivery.latestdate,
                        status: delivery.status,
                        note: delivery.note,
                        scheduledDeliveryDate: delivery.scheduledate
                    },
                    revision: delivery.revision
                })
                    .then(response => {
                        dispatch(orderActions.Request_Update_Order_Response({ data: response.data, err: null }))
                        resolve({ ...delivery })
                    }).catch(error => {
                        reject(new Error(error.response.data.message));
                    })
            }),
        //eslint-disable-next-line
        [],
    );
};

const columns = [
    { field: 'orderno', headerName: 'Order No', minWidth: 100, maxWidth: 170, hideable: false },
    { field: 'salesrep', headerName: 'Sales Rep', maxWidth: 170 },
    {
        field: 'client',
        headerName: 'Client',
        minWidth: 170,
        maxWidth: 250,
        sortable: false,
        filterable: false,
        renderCell: (params) => {
            var contactdetail = '';

            if (params.value.clientName !== '' && params.value.contact !== '') {
                contactdetail = `${params.value.clientName} - ${params.value.contact}`
            } else if (params.value.clientName !== '') {
                contactdetail = params.value.clientName;
            } else if (params.value.contact !== '') {
                contactdetail = params.value.contact
            }

            return <Cell style={{ display: 'flex', flexDirection: 'column' }}>
                <span style={{ textAlign: 'left' }}>{params.value.companyName}</span>
                <span style={{ textAlign: 'left' }}>{contactdetail}</span>
            </Cell>
        }
    },
    { field: 'scheduledate', headerName: 'Scheduled', minWidth: 120, maxWidth: 120, type: 'date', editable: true },
    {
        field: 'latestdate',
        headerName: 'Latest',
        minWidth: 120,
        maxWidth: 120,
        type: 'date',
        editable: true,
    },
    { field: 'status', headerName: 'Status', minWidth: 150, editable: true, renderEditCell: renderSelectEditInputCell },
    {
        field: 'paymentstatus',
        headerName: 'Payment',
        minWidth: 120,
        renderCell: (params) => {
            return <Cell style={getPaymentStatusStyle(params.value)}>{params.value}</Cell>
        }
    },
    { field: 'note', headerName: 'Note', minWidth: 200, renderCell: renderCellExpand, editable: true, sortable: false },
]

const Cell = (props) => {
    return (
        <div style={props.style}>{props.children}</div>
    )
}

const toCellFormat = (order) => {
    return {
        id: order._id,
        revision: order.revision,
        salesrep: order.salesrep,
        client: order.client,
        orderno: order.orderNo,
        scheduledate: order.delivery.scheduledDeliveryDate ? new Date(order.delivery.scheduledDeliveryDate) : null,
        latestdate: order.delivery.date ? new Date(order.delivery.date) : null,
        status: order.delivery.status,
        paymentstatus: order.paymentStatus,
        note: order.delivery.note
    }
}

const DeliveryTabVer2 = ({ orders }) => {

    const myOrders = useSelector(state => state.order.ordersV2);

    const uploadDelivery = useDeliveryUpload();

    const [snackbar, setSnackbar] = useState(null);
    const handleCloseSnackbar = () => setSnackbar(null);

    const processRowUpdate = useCallback(
        async (newRow) => {
            // Make the HTTP request to save in the backend
            const response = await uploadDelivery(newRow);
            setSnackbar({ children: 'Delivery successfully saved', severity: 'success' });
            return response;
        },
        [uploadDelivery],
    );

    const handleProcessRowUpdateError = useCallback((error) => {
        setSnackbar({ children: error.message, severity: 'error' });
    }, []);


    const rows = myOrders.map(toCellFormat)

    return (
        <Paper sx={{ width: '100%', display: 'flex', height: '100vh', '& .test': { backgroundColor: 'yellow' } }}>
            <DataGrid
                columns={columns}
                rows={rows}
                slots={{ toolbar: GridToolbar }}
                loading={!myOrders}
                localeText={{
                    toolbarDensity: 'Size',
                    toolbarDensityLabel: 'Size',
                    toolbarDensityCompact: 'Small',
                    toolbarDensityStandard: 'Medium',
                    toolbarDensityComfortable: 'Large',
                }}
                editMode="row"
                hideFooterSelectedRowCount={true}
                processRowUpdate={processRowUpdate}
                onProcessRowUpdateError={handleProcessRowUpdateError}
            />

            {!!snackbar && (
                <Snackbar
                    open
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    onClose={handleCloseSnackbar}
                    autoHideDuration={2000}
                >
                    <Alert {...snackbar} onClose={handleCloseSnackbar} />
                </Snackbar>
            )}
        </Paper>
    )
}

export default DeliveryTabVer2