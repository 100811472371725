import React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';

import dayjs from 'dayjs';

const ActivityList = ({ activities }) => {
    return (
        <React.Fragment>
            <span style={{fontSize:'1.4rem', fontWeight:600}}>Activity</span>

            <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                {
                    activities.map((activity, index) => {
                        return <React.Fragment key={index}>
                            <ListItem alignItems="flex-start" key={`${index}-li`}>
                                <ListItemText
                                    key={`${index}-lit`}
                                    primary={
                                        <React.Fragment>
                                            {/* {`${activity.name}: `}<br /> */}
                                            <Typography
                                                key={`${index}-typo`}
                                                component="span"
                                                variant="body2"
                                                color="text.primary"
                                            >
                                                {activity.log}
                                            </Typography>
                                        </React.Fragment>
                                    }
                                    secondary={dayjs(activity.date).format('DD-MM-YYYY hh:mm a')}
                                />
                            </ListItem>
                            { 
                                index !== activities.length - 1 &&
                                <Divider component="li" key={`${index}-lid`}/>
                            }
                        </React.Fragment>
                    })
                }
            </List>
        </React.Fragment>
    )
}

export default ActivityList