import React, { useState } from 'react';

import styles from './SearchBox.module.css';

import IconButton from '@mui/material/IconButton';
import { Close } from '@mui/icons-material';

import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

const datePattern = /^[0-9]{1,2}\/[0-9]{1,2}\/[0-9]{2}$/

const SearchBox = ({ onClose, onSearch }) => {

    const [searchTerm, setSearchTerm] = useState('')
    const [startDateStr, setStartDateStr] = useState('');
    const [endDateStr, setEndDateStr] = useState('');
    const [productSearch, setProductSearch] = useState(false)

    const searchHandler = () => {

        if (searchTerm.trim() === '' && startDateStr.trim() === '' && endDateStr.trim() === '') {
            onSearch(null);
            return;
        }

        const fields = {}

        if (searchTerm.trim() !== '') {
            fields['searchTerm'] = searchTerm.trim()
        }

        if (!!startDateStr.match(datePattern)) {
            fields['startDate'] = startDateStr
        }

        if (!!endDateStr.match(datePattern)) {
            fields['endDate'] = endDateStr
        }

        fields['productSearch'] = !!productSearch ? 1 : 0;

        onSearch(fields)
    }

    return (
        <div className={styles.container}>
            <IconButton aria-label="close" size="small" onClick={() => onClose(false)}>
                <Close fontSize='inherit' />
            </IconButton>
            <form className={styles.form}>

                <div style={{ display: 'flex', gap: '4px' }}>
                    <div className={styles['textfield-group']}>
                        <label className={styles.label}>Sales Rep, Client or Order No.</label>
                        <input
                            type="text"
                            className={styles.textfield}
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)} />
                    </div>
                    <div className={styles['textfield-group']}>
                        <label className={styles.label}>Start Date (dd/MM/YY)</label>
                        <input
                            type="text"
                            className={`${styles.textfield} ${styles['date-field']}`}
                            value={startDateStr}
                            onChange={(e) => setStartDateStr(e.target.value)} />
                    </div>
                    <div className={styles['textfield-group']}>
                        <label className={styles.label}>End Date (dd/MM/YY)</label>
                        <input
                            type="text"
                            className={`${styles.textfield} ${styles['date-field']}`}
                            value={endDateStr}
                            onChange={(e) => setEndDateStr(e.target.value)} />
                    </div>
                </div>

                <div style={{ display: 'flex', gap: '4px' }}>
                    <FormControlLabel
                        control={<Checkbox
                            color="success"
                            checked={productSearch}
                            onChange={(e) => setProductSearch(e.target.checked)} 
                            />
                        }
                        label="Product Search" />

                    {/* <div className={`${styles.textfield} ${styles['date-field']}`}></div>
                    <div className={`${styles.textfield} ${styles['date-field']}`}></div> */}
                </div>

            </form>

            <Button
                variant="contained"
                style={{
                    width: '100px',
                    backgroundColor: '#E41071',
                    margin: '0 8px 8px 0'
                }}
                onClick={searchHandler}>
                Search
            </Button>
        </div>
    )
}

export default SearchBox