
export const OrderConstant = {
    MaxImageUploadCountPerProduct: 3,
    MaxTotalImageSize: 10485760, //10MB, in Bytes
    MaxSizePerImage: 1048576,
    MaxProductAllowed: 10,

    MaxOrderRequestLimit: 100,
    RowsPerPage: [25, 50, 100],
}

export const LocalStorageConstant = {
    JwtKey: 'token'
}