import React, { useRef } from "react";

import styles from "./drop-file-input.module.css";
import uploadImg from "../../assets/icon/cloud-upload-regular-240.png";

const DropFileInput = (props) => {
  const wrapperRef = useRef(null);

  const onDragEnter = () => wrapperRef.current.classList.add("dragover");

  const onDragLeave = () => wrapperRef.current.classList.remove("dragover");

  const onDrop = () => wrapperRef.current.classList.remove("dragover");

  const onFileDrop = (e) => {
    const newFile = e.target.files[0];
    if (newFile) {
        props.onFileAdd(newFile);
    }
  };

  return (
    <div
      ref={wrapperRef}
      className={styles['drop-file-input']}
      onDragEnter={onDragEnter}
      onDragLeave={onDragLeave}
      onDrop={onDrop}
    >
      <div className={styles['drop-file-input__label']}>
        <img src={uploadImg} alt="" />
        <span>Drag & Drop your files here</span>
        {
          props.disabled && <span className={styles['alert-limit']}>Max 3 images limit reached</span>
        }
      </div>
      <input disabled={props.disabled} type="file" value="" accept=".jpeg, .png, .jpg" onChange={onFileDrop} />
    </div>
  );
};

export default DropFileInput;
