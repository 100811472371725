import React, { useState } from 'react'
import styles from './ProductionSheet.module.css';

import TextField from '@mui/material/TextField';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';

import ProductItem from './ProductItem';
import SupplierItem from './SupplierItem';
import DeliveryItem from './DeliveryItem';
import { addProduct } from '../../../../store/order-actions';
import { useDispatch } from 'react-redux';

const inputStyle = {
  "& .MuiInputBase-root": {
    height: 30,
    padding: 0
  },
  "& .MuiOutlinedInput-root.MuiInputBase-sizeSmall": {
    padding: 0
  },
  width: '100%'
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ mt: 2 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
    disableRipple: true,
    style: { color: '#444' }
  };
}

const ProductionSheet = (props) => {

  const dispatch = useDispatch();

  const {
    formHeader: formheader,
    clientFormData: client,
    productFormData: products,
    deliveryFormData: delivery,
    handleClientInputChange: handleClientChange,
    handleProductInputChange: handleProductChange,
    handleDeliveryInputChange: handleDeliveryChange,
    deleteProduct,
    overallcost
  } = props

  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  // useEffect(() => {

  //   //eslint-disable-next-line
  // }, [products])

  const onAddProduct = () => {
    dispatch(addProduct());
  }

  return (
    <React.Fragment>
      <span style={{ textAlign: 'center', fontWeight: 700, marginBottom: 16 }}>ORDER CONFIRMATION & PRODUCTION SHEET</span>
      <div className={styles.container}>

        <div className={styles['input-row']}>
          <div className={styles['input-group-25']}>
            Billing Company Name
            <TextField
              id="billingname"
              variant="outlined"
              size='small'
              name="companyName"
              sx={inputStyle}
              value={client.companyName}
              onChange={handleClientChange} />
          </div>
          <div className={styles['input-group-75']}>
            Billing Address
            <TextField
              id="billingaddr"
              variant="outlined"
              size='small'
              name='billingAddr'
              sx={inputStyle}
              value={client.billingAddr}
              onChange={handleClientChange} />
          </div>
        </div>

        <div className={styles['input-row']}>
          <div className={styles['input-group-25']}>
            Client Name
            <TextField
              id="clientname"
              variant="outlined"
              size='small'
              name='clientName'
              sx={inputStyle}
              value={client.clientName}
              onChange={handleClientChange} />
          </div>
          <div className={styles['input-group-75']}>
            Delivery Address
            <TextField
              id="deliveryaddr"
              variant="outlined"
              size='small'
              name='deliveryAddr'
              sx={inputStyle}
              value={client.deliveryAddr}
              onChange={handleClientChange} />
          </div>
        </div>

        <div className={styles['input-row']}>
          <div className={styles['contact-email-wrapper']}>
            <div className={styles['input-group-25']}>
              Contact
              <TextField
                id="contact"
                variant="outlined"
                size='small'
                name='contact'
                sx={inputStyle}
                value={client.contact}
                onChange={handleClientChange} />
            </div>
            <div className={styles['input-group-25']}>
              Email Address
              <TextField
                id="email"
                variant="outlined"
                size='small'
                name='email'
                sx={inputStyle}
                value={client.email}
                onChange={handleClientChange} />
            </div>
          </div>

          <div className={styles['input-group-75']}>
            Remarks
            <TextField
              id="remark"
              size='small'
              multiline
              rows={3}
              value={client.remark}
              name='remark'
              onChange={handleClientChange} />
          </div>
        </div>
      </div>

      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={tabValue} onChange={handleTabChange} aria-label="tabs" TabIndicatorProps={{ style: { backgroundColor: '#E41071' } }}>
            <Tab label="Item" {...a11yProps(0)} sx={{ p: 0 }} />
            <Tab label="Supplier" {...a11yProps(1)} sx={{ p: 0 }}/>
            <Tab label="Delivery" {...a11yProps(2)} />
          </Tabs>
        </Box>

        <TabPanel value={tabValue} index={0} className={styles['tabpanel-wrapper']}>
          {
            products.map((product, i) =>
              <React.Fragment key={`${product.id}-prod`}>
                <ProductItem
                  product={product}
                  formheader={formheader}
                  deleteProduct={deleteProduct}
                  onInputChange={handleProductChange}
                  index={i} />
                <Divider />
              </React.Fragment>
            )
          }

          {
            formheader.xeroInvoiceId === '' &&
            <div className={styles['add-product-wrapper']} onClick={onAddProduct}>
              <span>Add Product</span>
            </div>
          }
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          {
            products
              .filter(product => product.exportable)
              .map((product, i) =>
              <React.Fragment key={`${product.id}-supp`}>
                <SupplierItem
                  product={product}
                  onInputChange={handleProductChange}
                  index={i} />
                <Divider sx={{ margin: '16px 0' }} />
              </React.Fragment>
            )
          }
        </TabPanel>
        <TabPanel value={tabValue} index={2}>
          <DeliveryItem onInputChange={handleDeliveryChange} delivery={delivery}/>
        </TabPanel>
      </Box>

      {/* Total product cost tabulate before GST */}
      <div className={styles['cost-tabulate-wrapper']}>
        <span>Total Order Amount (before 9% GST):</span>
        <span>${overallcost.toFixed(2)}</span>
      </div>
    </React.Fragment>
  )
}

export default ProductionSheet