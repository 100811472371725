import { configureStore } from '@reduxjs/toolkit'

import userReducer from './user-slice';
import orderReducer from './order-slice';
import authReducer from './auth-slice';
import optionReducer from './option-slice';
import xeroReducer from './xero-slice';

const store = configureStore({
    reducer: {
        user: userReducer,
        order: orderReducer,
        auth: authReducer,
        option: optionReducer,
        xero: xeroReducer
    }
});

export default store;