import React, { useEffect, useState } from 'react'

import styles from './Dashboard.module.css';
import dayjs from 'dayjs';

import SearchBox from './SearchBox';
import OrderTable from './OrderTable';
import ActivityList from './ActivityList';
import DeliveryTabVer2 from './DeliveryTabVer2';
import Loading from '../../../Layout/Loading';
import { orderApiV2 } from '../../../Api';

import IconButton from '@mui/material/IconButton';
import { Add, Refresh, Search } from '@mui/icons-material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import LinearProgress from '@mui/material/LinearProgress';

import { userActions } from '../../../store/user-slice';
import { getOrders, getJournals, initOrder, resetOrderState, getOrdersV2, updateOrderV2Query } from '../../../store/order-actions';
import { useDispatch, useSelector } from 'react-redux';
import { getUsers } from '../../../store/user-actions';
import { useNavigate } from 'react-router-dom';

import ToastAlert from '../../../UI/Alert/ToastAlert';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
    disableRipple: true,
    style: { color: '#444' }
  };
}

const dashboardDate = dayjs().format('DD MMMM YYYY');

const Dashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const authUser = useSelector(state => state.auth.authUser);
  const requestingOrders = useSelector(state => state.order.req_orders_loading);
  //const myOrders = useSelector(state => state.order.orders);
  const journals = useSelector(state => state.order.journals);
  const users = useSelector(state => state.user.users);
  const queries = useSelector(state => state.order.filteredOrdersQuery);

  //New order version 2 support pagination
  const myOrdersV2 = useSelector(state => state.order.ordersV2);

  const initOrderResponse = useSelector(state => state.order.req_order_init_response);
  const initOrderError = useSelector(state => state.order.req_order_init_error);
  const initOrderLoading = useSelector(state => state.order.req_order_init_loading);

  //const deleteOrderResponse = useSelector(state => state.order.req_order_delete_response);
  const deleteOrderError = useSelector(state => state.order.req_order_delete_error);

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [errorText, setErrorText] = useState(null);

  const [selectedUser, setSelectedUser] = useState('');
  const [orders, setOrders] = useState(myOrdersV2)

  const [selectedSalesDate, setSelectedSalesDate] = useState(dayjs());
  const [ordersOfSelectedDate, setOrdersOfSelectedMonth] = useState([]);
  const [userSalesOfSelectedDate, setUserSalesOfSelectedDate] = useState(0);
  const [totalSalesOfSelectedDate, setTotalSalesOfSelectedDate] = useState(0);
  const [isDownloadingOrdersOfTheMth, setIsDownloadingOrdersOfTheMth] = useState(false);

  //Search state
  const [searchDisplay, setSearchDisplay] = useState(false)
  const [searchField, setSearchField] = useState(null);

  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    dispatch(userActions.Clear_Errors());

    dispatch(getOrders());
    dispatch(getOrdersV2(1));
    dispatch(getJournals());
    dispatch(getUsers(['_id', 'firstname', 'lastname']));

    if(!!queries?.status){
      if(queries.status === 'Approved'){
        setTabValue(1);
      }else if(queries.status === 'Sent'){
        setTabValue(2);
      }else if(queries.status === 'Draft'){
        setTabValue(3);
      }else if(queries.status === 'Cancelled'){
        setTabValue(4);
      }
    }else{
      setTabValue(0);
    }

    //eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (initOrderError || deleteOrderError) {
      setOpenSnackbar(true);
      setErrorText(initOrderError ?? deleteOrderError);

    } else {
      setErrorText(null);
      setOpenSnackbar(false);
    }
    //eslint-disable-next-line
  }, [initOrderError, deleteOrderError])

  useEffect(() => {
    if (initOrderResponse) {
      navigate(`/order/${initOrderResponse.id}`)
    }

    //eslint-disable-next-line
  }, [initOrderResponse])

  useEffect(() => {
    var totalsales = 0;
    var salesrepsales = 0;

    for(var i = 0; i < ordersOfSelectedDate.length; i++){
      const order = ordersOfSelectedDate[i];

      if(order.status === 'Approved' || order.status === 'Sent'){
        totalsales += order.totalAmtBeforeGST;

        if(order.salesrepid === selectedUser){
          salesrepsales += order.totalAmtBeforeGST;
        }
      }
    }

    setUserSalesOfSelectedDate(salesrepsales);
    setTotalSalesOfSelectedDate(totalsales);
  }, [ordersOfSelectedDate, selectedUser])

  useEffect(() => { 
    //console.log(myOrdersV2) 
    setOrders(myOrdersV2)
  }, [myOrdersV2])

  useEffect(() => {
    dispatch(getOrdersV2(1,{}, true));
    //eslint-disable-next-line
  },[queries])

  useEffect(() => {
    if(users.length > 0){
      setSelectedUser(authUser._id)
    }
    //eslint-disable-next-line
  }, [users])

  useEffect(() => {
    //Each time it triggers,
    setIsDownloadingOrdersOfTheMth(true);
    const date = selectedSalesDate['$d']
    
    orderApiV2.get(`/stats?month=${date.getMonth()}&year=${date.getFullYear()}`)
      .then(res => {
        setOrdersOfSelectedMonth(res.data);
        setIsDownloadingOrdersOfTheMth(false);
      }).catch(err => {
        setErrorText(err.response?.data ?? err.message);
        setOpenSnackbar(true);
        setIsDownloadingOrdersOfTheMth(false);
      })
  }, [selectedSalesDate])  

  const handleSearchDisplayClick = (display) => {
    setSearchDisplay(display);
  }

  const onSearchClicked = (fields) => {
    if (!fields) {
      //dispatch(getOrdersV2(1,null,true));
      dispatch(updateOrderV2Query({searchTerm:null,startDate:null,endDate:null}))
    } else {
      //dispatch(getOrdersV2(1, fields, true));
      dispatch(updateOrderV2Query(fields))
    }
    //console.log(fields)
    setSearchField(fields)
    
  }

  const refreshHandler = () => {
    if (!!searchField) {
      dispatch(getOrdersV2(1, searchField, true));
    } else {
      dispatch(getOrdersV2(1));
    }
  }

  const handleTabChange = (event, newValue) => {

    if (!myOrdersV2) {
      return;
    }

    //let filtered = myOrdersV2;

    if (newValue === 0) {
      //dispatch(getOrdersV2(1,{},true));
      dispatch(updateOrderV2Query({status:null}))
      //setOrders(filtered);
    } else if (newValue === 1) {
      //filtered = myOrdersV2.filter(order => order.status === 'Approved');
      //dispatch(getOrdersV2(1,{status:'Approved'}));
      //setOrders(filtered)

      dispatch(updateOrderV2Query({status:'Approved'}))

    } else if (newValue === 2) {
      //dispatch(getOrdersV2(1,{status:'Sent'}));
      dispatch(updateOrderV2Query({status:'Sent'}))
      //filtered = myOrdersV2.filter(order => order.status === 'Sent');
      //setOrders(filtered)
    } else if (newValue === 3) {
      //filtered = myOrdersV2.filter(order => order.status === 'Draft');
      //setOrders(filtered)
      dispatch(updateOrderV2Query({status:'Draft'}))
    } else if (newValue === 4) {
      //dispatch(getOrdersV2(1,{status:'Cancelled'}));
      //filtered = myOrdersV2.filter(order => order.status === 'Cancelled');
      //setOrders(filtered)
      dispatch(updateOrderV2Query({status:'Cancelled'}))
    }
    
    setTabValue(newValue);
  };

  const onDateSelected = (e) => {
    
    if(e['$M'] === selectedSalesDate['$M'] && e['$y'] === selectedSalesDate['$y']){
      return;
    }
    
    setSelectedSalesDate(dayjs(e['$d']))
  }

  function renderOrderTable() {
    return <>
      <TabPanel value={tabValue} index={0}>
        <OrderTable orders={orders} />
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <OrderTable orders={orders} />
      </TabPanel>
      <TabPanel value={tabValue} index={2}>
        <OrderTable orders={orders} />
      </TabPanel>
      <TabPanel value={tabValue} index={3}>
        <OrderTable orders={orders} />
      </TabPanel>
      <TabPanel value={tabValue} index={4}>
        <OrderTable orders={orders} />
      </TabPanel>
      <TabPanel value={tabValue} index={5}>
        {/* <DeliveryTab orders={orders} /> */}
        <DeliveryTabVer2 orders={orders} />
      </TabPanel>
    </>
  }

  function renderSalesRepSelectionSales() {
    return (
      <>
        <div>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              sx={{ width: '100%' }}
              views={['month', 'year']}
              value={selectedSalesDate}
              onYearChange={onDateSelected} />
          </LocalizationProvider >
          {isDownloadingOrdersOfTheMth && <LinearProgress />}
        </div>
        <Select
          value={selectedUser}
          onChange={(event) => setSelectedUser(event.target.value)}
          displayEmpty
          disabled={users.length === 0}
          inputProps={{ 'aria-label': 'Without label' }}>
          {
            users.map(user => {
                return <MenuItem key={user._id} value={user._id}>
                  {`${user.firstname} ${user.lastname}`}
                </MenuItem>
              })
          }
        </Select>
      </>
    )
  }

  return (
    <div className={styles.main}>

      {/* Left section */}
      <section className={styles['main-section']}>
        <div className={styles.header}>
          <span>Welcome to Dashboard</span>
          <span className={styles.date}>{dashboardDate}</span>
        </div>

        <div className={styles['table-section']}>
          <div className={styles['table-wrapper']}>
            {
              initOrderError &&
              <div className={styles['error-banner']}>
                <span>Something went wrong with creating order.</span>
                <span>Error: {initOrderError}</span>
              </div>
            }
            <div style={{ display: 'flex' }}>

              <div className={styles['order-header']}>
                Orders
                {requestingOrders && <Loading loadingText='' style={{ width: '100%' }} />}
              </div>
              <div className={styles['order-actions']}>
                <IconButton
                  aria-label="add"
                  size="small"
                  disabled={initOrderLoading}
                  onClick={() => dispatch(initOrder())}>
                  <Add fontSize='inherit' />
                </IconButton>
                <IconButton
                  aria-label="refresh"
                  size="small"
                  onClick={() => handleSearchDisplayClick(true)}>
                  <Search fontSize='inherit' />
                </IconButton>
                <IconButton aria-label="search" size="small" onClick={refreshHandler}>
                  <Refresh fontSize='inherit' />
                </IconButton>
              </div>
            </div>

            {searchDisplay && <SearchBox onClose={handleSearchDisplayClick} onSearch={onSearchClicked} />}

            <Box sx={{ width: '100%' }}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={tabValue} onChange={handleTabChange} aria-label="tabs" TabIndicatorProps={{ style: { backgroundColor: '#E41071' } }}>
                  <Tab label="All" {...a11yProps(0)} />
                  <Tab label="Approved" {...a11yProps(1)} />
                  <Tab label="Sent" {...a11yProps(2)} />
                  <Tab label="Draft" {...a11yProps(3)} />
                  <Tab label="Cancelled" {...a11yProps(4)} />
                  <Tab label="Delivery" {...a11yProps(5)} />
                </Tabs>
              </Box>

              {myOrdersV2 && renderOrderTable()}
            </Box>
          </div>
        </div>
      </section>

      {/* Right section */}
      <section className={styles['activity-section']}>
        <div className={styles['sales-of-the-month-wrapper']}>
          {selectedUser && renderSalesRepSelectionSales()}
          <span style={{ fontWeight: '700' }}>Sales of the Month</span>
          <span style={{ fontWeight: '300', fontSize: '2rem' }}>
            {userSalesOfSelectedDate.toFixed(2)}
          </span>
          <span style={{ fontWeight: '700' }}>Total Sales of the Month</span>
          <span style={{ fontWeight: '300', fontSize: '2rem' }}>
            {totalSalesOfSelectedDate.toFixed(2)}
          </span>
        </div>
        <hr className={styles.hr} />
        <div className={styles['activity-wrapper']}>
          <ActivityList activities={journals} />
        </div>
      </section>

      <ToastAlert
        open={openSnackbar}
        onClose={() => {
          dispatch(resetOrderState(initOrderError ? 'create' : 'delete'))
        }}
        message={errorText}
        type='error'
      />
    </div>
  )
}

export default Dashboard