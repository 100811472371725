import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    user: null,
    users: [],

    req_loading: false,
    req_error: null,

    req_delete_loading: false,
    req_delete_error: null,

    req_user_loading: false,
    req_user_error: null,

    req_add_response: null,
    req_add_error: null,
    req_add_loading: false,

    req_update_response: null,
    req_update_loading: false,
    req_update_error: null,
}

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers:{
        Request_Users(state) {
            state.req_loading = true;
            state.req_error = null;
        },
        Request_Users_Response(state, action) {
            state.req_loading = false;
            state.req_error = action.payload?.err
            state.users = action.payload.data ?? state.users
        },
        Request_User(state) {
            state.req_user_loading = true;
            state.req_user_error = null;
            state.user = null;
        },
        Request_User_Response(state, action) {
            state.req_user_loading = false;
            state.req_user_error = action.payload?.err
            state.user = action.payload.data
        },
        Clear_Errors(state){
            state.req_error = null;
            state.req_user_error = null;
            state.req_update_error = null;
            state.req_delete_error = null;
            state.req_update_response = null;
        },
        Update_User(state){
            state.req_update_loading = true;
            state.req_update_error = null;
        },
        Update_User_Response(state, action){
            state.req_update_response = action.payload?.data
            state.user = action.payload?.data || state.user
            state.req_update_loading = false;
            state.req_update_error = action.payload?.err

            state.users = action.payload.data ? 
                state.users.map((user) => {
                    if(user._id === action.payload.data._id){
                        return action.payload.data;
                    }

                    return user;
                }) : 
                state.users
        },
        Request_Add_User(state) {
            state.req_loading = true;
            state.req_error = null;
        },
        Request_Add_User_Response(state, action) {
            state.req_add_loading = false;
            state.req_add_error = action.payload.err
            state.req_add_response = action.payload.data
            
            if(action.payload.data){
                state.users.push(action.payload.data)
            }
        },
        Request_Delete_User(state) {
            state.req_delete_loading = true;
            state.req_delete_error = null;
        },
        Request_Delete_User_Response(state, action) {
            state.req_delete_loading = false;
            state.req_delete_error = action.payload?.err

            state.users = state.users.filter(user => user._id !== action.payload.data._id)
        },
    }
})

export default userSlice.reducer;
export const userActions = userSlice.actions;