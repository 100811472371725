
import jwtDecode from "jwt-decode";
import { Navigate } from "react-router-dom";
import { authActions } from "./auth-slice";
import { AuthApi, userApiV2 } from "../Api";

var tokenExpiry = null;

export const getAuthUserToken = () => {
    const user = localStorage.getItem('userData');
    const token = localStorage.getItem('token');

    if(!user || !token){
        return null;
    }

    if(!tokenExpiry) {
        const decodedToken = jwtDecode(token);
        tokenExpiry = new Date(decodedToken.exp*1000);
    }

    const today = new Date();

    return today < tokenExpiry ? token : null;
}

const sendLogoutApi = async () => {
    await fetch(AuthApi.logoutUrl, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
    });
}

//custom action creator
export const authenticate = (email, password) => {
    return async (dispatch) => {

        dispatch(authActions.onLoginStart());

        const sendReq = async () => {
            const response = await fetch(AuthApi.loginUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*'
                },
                body: JSON.stringify({ email, password })
            });

            const data = await response.json();

            if (!response.ok) {
                throw new Error(data.message)
            }

            return data;
        }

        try {
            const data = await sendReq();
            const userData = { ...data.user, token: data.token }
            handleAuthentication(userData, dispatch);

            dispatch(authActions.onLoginSuccess(userData))
        } catch (err) {
            dispatch(authActions.onLoginFailed(err.message))
        }
    }
}

const handleAuthentication = (userData, dispatch) => {
    localStorage.setItem('userData', JSON.stringify(userData));
    localStorage.setItem('token', userData.token);

    const token = jwtDecode(userData.token)

    setTimeout(timedLogout, token.exp, dispatch)
}

const timedLogout = async (dispatch) => {
    
    await sendLogoutApi();
    dispatch(authActions.logout());
    <Navigate to="/login" replace />
}

export const logout = () => {
    return async (dispatch) => {
        try {
            dispatch(authActions.logout());
            
            sendLogoutApi();

            localStorage.removeItem('userData');
            localStorage.removeItem('token')
            
        } catch (err) { }
    }
}

export const autologin = () => {
    return (dispatch) => {
        const userJson = localStorage.getItem('userData');

        if (!userJson) {
            return
        }

        const user = JSON.parse(userJson);

        dispatch(authActions.onLoginSuccess(user));
    }
}

export const refreshProfile = () => {
    return async (dispatch, getState) => {
        const authuser = getState().auth.authUser;
        
        userApiV2.get(`/${authuser._id}`)
            .then(res => {
                dispatch(updateAuthUser(res.data))
            })
    }
}

export const updateAuthUser = (updates) => {
    return async (dispatch) => {
        try {
            const userJson = localStorage.getItem('userData');
            const authUser = {
                ...JSON.parse(userJson),
                ...updates
            }

            localStorage.setItem('userData', JSON.stringify(authUser));

            dispatch(authActions.Update(authUser))
        } catch (err) { }
    }
}



export const setAuthCode = (authcode) => {
    return (dispatch) => {
        dispatch(authActions.SetAuthCode(authcode))
    }
}