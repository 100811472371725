import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import styles from './SupplierItem.module.css';

import TextField from '@mui/material/TextField';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Autocomplete from '@mui/material/Autocomplete';
import dayjs from 'dayjs';

const inputStyle = {
  "& .MuiInputBase-root": {
    height: 30,
    padding: 0
  },
  "& .MuiOutlinedInput-root.MuiInputBase-sizeSmall": {
    padding: 0
  },
  "& .MuiOutlinedInput-input": {
    height: 30,
    padding: '0 8px'
  },
  flex: '0 0 auto'
}

const ProductSupplier = 'productSupplier';
const PrintMaterialSupplier = 'printMaterialSupplier';
const PrintSvcSupplier = 'printServiceSupplier';

function toDateInputFormat(date) {
  return date ? dayjs(date).format('YYYY-MM-DD') : ''
}

const SupplierItem = ({ product, index, onInputChange }) => {
  
  const options = useSelector(state => state.option.options);

  const [productSupplierOptions, setProductSupplierOptions] = useState([]);
  const [printMatSupplierOptions, setPrintMatSupplierOptions] = useState([]);
  const [printSvcSupplierOptions, setPrintSvcSupplierOptions] = useState([]);

  useEffect(() => {
    const suppliers = options.find(opt => opt.name === 'supplier')?.value;

    if (suppliers) {
      const prodSupps = suppliers.filter(s => s.product).map(s => s.name);
      const printMatSupps = suppliers.filter(s => s.printMaterial).map(s => s.name);
      const printSvcSupps = suppliers.filter(s => s.printService).map(s => s.name);

      setProductSupplierOptions(prodSupps);
      setPrintMatSupplierOptions(printMatSupps);
      setPrintSvcSupplierOptions(printSvcSupps);
    }
    //eslint-disable-next-line
  }, [options.length])

  const supplierInputHandler = (supType, event) => {
    const inputFieldValue = event.target.value;
    const inputFieldName = event.target.name;

    onInputChange({
      target: {
        name: supType,
        value: {
          ...product[supType],
          [inputFieldName]: inputFieldValue
        }
      }
    }, index)
  }

  const prodSuppNameAutoCompleteHandler = (e, newVal) => {
    supplierInputHandler(ProductSupplier, { target: { name: 'name', value: newVal } })
  }

  const printMatSuppAutoCompleteHandler = (e, newVal) => {
    supplierInputHandler(PrintMaterialSupplier, { target: { name: 'name', value: newVal } })
  }

  const printSvcSuppAutoCompleteHandler = (e, newVal) => {
    supplierInputHandler(PrintSvcSupplier, { target: { name: 'name', value: newVal } })
  }

  const onChange = (event) => {

    const namesplit = event.target.name.split('.');
    const name = namesplit[1];

    const value = name === 'cost' ? +event.target.value : event.target.value;

    supplierInputHandler(namesplit[0], { target: { name, value } })
  }

  const onCheckedPrintMatSuppHandler = (e) => {
    if (!e.target.checked) { return; }

    onInputChange({
      target: {
        name: PrintMaterialSupplier,
        value: {
          name: product.productSupplier.name,
          shipDate: product.productSupplier.shipDate,
          shipMode: product.productSupplier.shipMode,
          cost: product.productSupplier.cost
        }
      }
    }, index)
  }

  const onCheckedPrintSvcSuppHandler = (e) => {
    if (!e.target.checked) { return; }

    onInputChange({
      target: {
        name: PrintSvcSupplier,
        value: {
          name: product.productSupplier.name,
          shipDate: product.productSupplier.shipDate,
          shipMode: product.productSupplier.shipMode,
          cost: product.productSupplier.cost
        }
      }
    }, index)
  }

  return (
    <div className={styles.container}>
      <div>
        <div className={styles['input-group-product']}>
          Product
          <TextField
            id="productname"
            variant="outlined"
            size='small'
            sx={inputStyle}
            value={product.name}
            disabled
          />
        </div>

        {/* Product supplier */}
        <div className={styles['input-row']}>
          <div className={styles['input-group']}>
            Product Supplier
            <Autocomplete
              id="productsupplier"
              size='small'
              options={productSupplierOptions}
              sx={inputStyle}
              freeSolo
              value={product.productSupplier.name}
              onChange={prodSuppNameAutoCompleteHandler}
              renderInput={(params) =>
                <TextField
                  {...params}
                  name={`${ProductSupplier}.name`}
                  onChange={onChange} />
              }
            />
          </div>
          <div className={styles['input-group']}>
            Ship Date
            <TextField
              id="prodshipdate"
              variant="outlined"
              size='small'
              sx={inputStyle}
              type="date"
              name={`${ProductSupplier}.shipDate`}
              value={toDateInputFormat(product.productSupplier.shipDate)}
              onChange={onChange} />
          </div>
          <div className={styles['input-group']}>
            Ship Mode
            <TextField
              id="prodshipmode"
              name={`${ProductSupplier}.shipMode`}
              variant="outlined"
              size='small'
              sx={inputStyle}
              value={product.productSupplier.shipMode}
              onChange={onChange} />
          </div>
          <div className={styles['input-group']}>
            Cost
            <OutlinedInput
              sx={inputStyle}
              id="prodcost"
              name={`${ProductSupplier}.cost`}
              type="number"
              startAdornment={<InputAdornment position="start">$</InputAdornment>}
              value={product.productSupplier.cost}
              onChange={onChange}
            />
          </div>
          <div className={styles['checkbox-wrapper']}></div>
        </div>

        {/* Print material supplier */}
        <div className={styles['input-row']}>
          <div className={styles['input-group']}>
            Print Material Supplier
            <Autocomplete
              id="printmatsupplier"
              size='small'
              options={printMatSupplierOptions}
              sx={inputStyle}
              freeSolo
              value={product.printMaterialSupplier.name}
              onChange={printMatSuppAutoCompleteHandler}
              renderInput={(params) =>
                <TextField
                  {...params}
                  name={`${PrintMaterialSupplier}.name`}
                  onChange={onChange} />
              }
            />
          </div>
          <div className={styles['input-group']}>
            Ship Date
            <TextField
              id="printmatshipdate"
              name={`${PrintMaterialSupplier}.shipDate`}
              variant="outlined"
              size='small'
              sx={inputStyle}
              type="date"
              value={toDateInputFormat(product.printMaterialSupplier.shipDate)}
              onChange={onChange} />
          </div>
          <div className={styles['input-group']}>
            Ship Mode
            <TextField
              id="printmatshipmode"
              name={`${PrintMaterialSupplier}.shipMode`}
              variant="outlined"
              size='small'
              sx={inputStyle}
              value={product.printMaterialSupplier.shipMode}
              onChange={onChange} />
          </div>
          <div className={styles['input-group']}>
            Cost
            <OutlinedInput
              sx={inputStyle}
              id="printmatcost"
              name={`${PrintMaterialSupplier}.cost`}
              type="number"
              startAdornment={<InputAdornment position="start">$</InputAdornment>}
              value={product.printMaterialSupplier.cost}
              onChange={onChange} />
          </div>
          <div className={styles['checkbox-wrapper']}>
            <FormControlLabel
              sx={{ margin: 0, "& .MuiTypography-root": { fontSize: '0.8rem' } }}
              control={<Checkbox
                defaultChecked={false}
                sx={{ '& .MuiSvgIcon-root': { fontSize: 20 } }} />}
              label="Same as product"
              onChange={onCheckedPrintMatSuppHandler} />
          </div>
        </div>

        {/* Print service supplier */}
        <div className={styles['input-row']}>
          <div className={styles['input-group']}>
            Print Service Supplier
            <Autocomplete
              id="printsvcsupplier"
              size='small'
              options={printSvcSupplierOptions}
              sx={inputStyle}
              freeSolo
              value={product.printServiceSupplier.name}
              onChange={printSvcSuppAutoCompleteHandler}
              renderInput={(params) =>
                <TextField
                  {...params}
                  name={`${PrintSvcSupplier}.name`}
                  onChange={onChange} />
              }
            />
          </div>

          <div className={styles['input-group']}>
            Ship Date
            <TextField
              id="printsvcshipdate"
              name={`${PrintSvcSupplier}.shipDate`}
              variant="outlined"
              size='small'
              sx={inputStyle}
              type="date"
              value={toDateInputFormat(product.printServiceSupplier.shipDate)}
              onChange={onChange} />
          </div>
          <div className={styles['input-group']}>
            Ship Mode
            <TextField
              id="printsvcshipmode"
              name={`${PrintSvcSupplier}.shipMode`}
              variant="outlined"
              size='small'
              sx={inputStyle}
              value={product.printServiceSupplier.shipMode}
              onChange={onChange} />
          </div>
          <div className={styles['input-group']}>
            Cost
            <OutlinedInput
              sx={inputStyle}
              name={`${PrintSvcSupplier}.cost`}
              id="printsvccost"
              type="number"
              startAdornment={<InputAdornment position="start">$</InputAdornment>}
              value={product.printServiceSupplier.cost}
              onChange={onChange} />
          </div>
          <div className={styles['checkbox-wrapper']}>
            <FormControlLabel
              sx={{ margin: 0, "& .MuiTypography-root": { fontSize: '0.8rem' } }}
              control={<Checkbox 
                        defaultChecked={false} 
                        sx={{ '& .MuiSvgIcon-root': { fontSize: 20 } }} />} 
                        label="Same as product"
                        onChange={onCheckedPrintSvcSuppHandler} />
          </div>
        </div>
      </div>

    </div>
  )
}

export default SupplierItem