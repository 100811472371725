import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    options: [],

    req_options_loading: false,
    req_options_error: null,
    req_options_response: null,
}

const optionSlice = createSlice({
    name: 'option',
    initialState,
    reducers:{
        Request_Options(state){
            state.req_options_loading = true;
            state.req_options_error = false;
            state.req_options_response = false;
        },
        Request_Options_Response(state, action) {
            state.req_options_loading = false;
            state.req_options_error = action.payload?.err;
            state.req_options_response = action.payload?.data;
            
            state.options = action.payload?.data;
        }
    }
})

export default optionSlice.reducer;
export const optionActions = optionSlice.actions;