import React from 'react';

import styles from './Navbar.module.css';

import { NavLink } from "react-router-dom";
import { useSelector } from 'react-redux';

const Navbar = () => {

  const activeStyle = (isActive) => {
    return isActive ? `${styles.active} ${styles['nav-item']}` : styles['nav-item']
  }
  //nav-item

  const authUser = useSelector(state => state.auth.authUser);

  return (
    <>
      <div className={styles.nav}>
        <NavLink to="/" className={
          ({ isActive }) => activeStyle(isActive)
        }>Dashboard</NavLink>

        {
          authUser?.role !== 'Standard' &&
          <NavLink to="/user" className={
            ({ isActive }) => activeStyle(isActive)
          }>User</NavLink>
        }


      </div>
    </>
  )
}

export default Navbar