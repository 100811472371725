import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';

import FormHeader from './FormHeader';
import FormOverview from './FormOverview';
import ProductionSheet from './ProductionSheet';
import Divider from '@mui/material/Divider';

import styles from './OrderForm.module.css';
import Loading from '../../../../Layout/Loading';
import ToastAlert from '../../../../UI/Alert/ToastAlert';
import { orderActions } from '../../../../store/order-slice';
import { SetProducts, updateOrder } from '../../../../store/order-actions';
import { syncXeroInvoiceToOrder } from '../../../../store/xero-actions'
import { useNavigate } from 'react-router-dom';

import Button from '@mui/material/Button';
import { SyncProblem, CloudSync } from '@mui/icons-material';

const dateFormat = 'DD/MM/YYYY hh:mm a'

const OrderForm = ({ order, xeroitem }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [validForm, setValidForm] = useState(true);
    const [edited, setEdited] = useState(false);
    const image_product_edited = useSelector(state => state.order.image_product_edited);

    const isUpdating = useSelector(state => state.order.req_order_update_loading);
    const updateResponse = useSelector(state => state.order.req_order_update_response);
    const updateError = useSelector(state => state.order.req_order_update_error);

    const productErr = useSelector(state => state.order.add_product_error);
    const imageErr = useSelector(state => state.order.add_image_error);
    const deleteErr = useSelector(state => state.order.req_order_delete_error);
    const deleteErrMsg = deleteErr ? 'Error deleting order, please refresh and try again.' : null;
    const hasSalesRepOptionErr = useSelector(state => state.user.req_error);
    const hasOptionErr = useSelector(state => state.option.req_options_error);
    const hasProductError = !!productErr || !!imageErr || !!deleteErr || !!hasSalesRepOptionErr || !!hasOptionErr;

    const deleteSuccessRes = useSelector(state => state.order.req_order_delete_response);

    //Alert state
    const [openSnackbar, setOpenSnackBar] = useState(false);
    const [snackMessage, setSnackMessage] = useState('');
    const [snackType, setSnackType] = useState('success');

    const [formHeaderInputData, setFormHeaderInputData] = useState({
        orderNo: order.orderNo,
        status: order.status,
        createdAt: dayjs(order.createdAt).format(dateFormat),
        salesrep: order.salesrep,
        updatedAt: dayjs(order.updatedAt).format(dateFormat),
        updatedBy: order.updatedBy,
        paymentStatus: order.paymentStatus,
        binded: order.xeroInvoiceId !== '',
        xeroInvoiceId: order.xeroInvoiceId
    });

    const [formOverviewInputData, setFormOverviewInputData] = useState({
        salesrepid: order.salesrepid,
        salesrep: order.salesrep,
        salesrepContact: order.salesrepContact,
        poNo: order.poNo,
        quoteNo: order.quoteNo,
        invoiceNo: order.invoiceNo,
        sampleDelivery: order.sampleDelivery,
        massOrderDelivery: order.massOrderDelivery,
        paymentTerm: order.paymentTerm,
        paymentMode: order.paymentMode,
        paymentDueDate: order.paymentDueDate
    });
    
    const [formDeliveryInputData, setFormDeliveryInputData] = useState({
        date: order.delivery.date,
        status: order.delivery.status,
        note: order.delivery.note,
        logs: order.delivery.logs
    })

    const [client, setClient] = useState({
        companyName: order.client.companyName,
        billingAddr: order.client.billingAddr,
        clientName: order.client.clientName,
        deliveryAddr: order.client.deliveryAddr,
        contact: order.client.contact,
        email: order.client.email,
        remark: order.client.remark
    })

    const [products, setProducts] = useState(order.products)

    const [totalAmtBeforeGST, setTotalAmtBeforeGST] = useState(order.totalAmtBeforeGST)

    useEffect(() => {
        setProducts(order.products);
    }, [order.products])

    useEffect(() => {
        if (xeroitem) {
            setFormHeaderInputData({
                ...formHeaderInputData,
                xeroInvoiceId: xeroitem?.quoteInv?.id ?? '',
                paymentStatus: xeroitem.paymentStatus,
                binded: (xeroitem?.quoteInv?.id ?? '') !== ''
            })

            setClient({
                ...client,
                companyName: xeroitem.companyName,
                billingAddr: xeroitem.billingAddr ?? '',
                deliveryAddr: xeroitem.deliveryAddr ?? '',
                clientName: xeroitem.contactInfo?.name ?? '',
                contact: xeroitem.contactPhone ?? '',
                email: xeroitem.contactInfo?.email ?? ''
            })

            if (xeroitem.quoteInv) {
                if (xeroitem.quoteInv.type === 'Quote') {
                    setFormOverviewInputData({
                        ...formOverviewInputData,
                        quoteNo: xeroitem.quoteInv.name,
                        invoiceNo: '',
                        paymentDue: ''
                    })
                } else {
                    // const dueDate = xeroitem.quoteInv.duedate;
                    // const paymentDue = dueDate ? dayjs(dueDate).format('DD-MMM-YYYY') : '';
                    
                    setFormOverviewInputData({
                        ...formOverviewInputData,
                        quoteNo: '',
                        invoiceNo: xeroitem.quoteInv.name,
                        paymentDueDate: xeroitem.quoteInv.duedate
                    })
                }

                //Products
                var totalcost = 0;

                dispatch(SetProducts(xeroitem.quoteInv.LineItems))

                xeroitem.quoteInv.LineItems.forEach(li => {
                    totalcost += (li.LineAmount ?? 0)
                })

                setTotalAmtBeforeGST(totalcost);
            }
        }
        //eslint-disable-next-line
    }, [xeroitem])

    //Form validation
    useEffect(() => {
        if (formOverviewInputData.salesrepid === '' || !!productErr || !!imageErr) {
            setValidForm(false);
            return
        }

        //assuming everything checks out, final check on edited.
        setValidForm(edited || image_product_edited);
    }, [formOverviewInputData.salesrepid, edited, productErr, imageErr, image_product_edited])

    useEffect(() => {
        if (updateResponse) {
            setSnackType('success');
            setSnackMessage('Order update successful!')
            setOpenSnackBar(true)
            setEdited(false);
        }

        if (updateError) {
            setSnackType('error');
            setSnackMessage(updateError);
            setOpenSnackBar(true);
            setEdited(true);
        }
    }, [updateResponse, updateError])

    useEffect(() => {
        if (deleteSuccessRes) {
            setSnackType('success');
            setSnackMessage('Order deleted')
            setOpenSnackBar(true)
            //dispatch(orderActions.Reset_Update_Order_State());
            //navigate('/order');
        }
    }, [deleteSuccessRes])

    const handleFormHeaderInputChange = (event) => {
        setEdited(true);
        const inputFieldValue = event.target.value;
        const inputFieldName = event.target.name;

        const newInputValue = {
            ...formHeaderInputData,
            [inputFieldName]: inputFieldValue
        };

        setFormHeaderInputData(newInputValue)
    };

    const handleFormOverviewInputChange = (event) => {
        setEdited(true);
        const inputFieldValue = event.target.value;
        const inputFieldName = event.target.name;

        let newInputValue = { ...formOverviewInputData, [inputFieldName]: inputFieldValue };

        if (inputFieldName === 'salesrepACField') {
            if (inputFieldValue) {
                newInputValue.salesrepid = inputFieldValue._id;
                newInputValue.salesrep = `${inputFieldValue.firstname} ${inputFieldValue.lastname}`.trim();
                newInputValue.salesrepContact = inputFieldValue.contact;
            } else {
                newInputValue.salesrepid = '';
                newInputValue.salesrep = '';
            }
        }

        setFormOverviewInputData(newInputValue);
    }

    const handleClientInputChange = (event) => {
        setEdited(true);
        const inputFieldValue = event.target.value;
        const inputFieldName = event.target.name;

        let newInputValue = { ...client, [inputFieldName]: inputFieldValue };

        setClient(newInputValue);
    }

    const handleProductInputChange = (event, index) => {
        
        setEdited(true);
        var inputFieldValue = event.target.value;
        const inputFieldName = event.target.name;
        
        const productCostUpdated = inputFieldName === 'qty' || inputFieldName === 'unitCost';
        //|| inputFieldName === 'deliveryFee' || inputFieldName === 'packaging';

        if(productCostUpdated){
            inputFieldValue = +inputFieldValue;
        }else if(inputFieldName === 'exportable'){
            inputFieldValue = event.target.checked
        }

        let totalcost = 0;

        const newProducts = products.map((prod, i) => {
            if (i === index) {
                const clonedProd = { ...prod, [inputFieldName]: productCostUpdated ? +inputFieldValue : inputFieldValue }

                if (productCostUpdated) {
                    //clonedProd.totalAmt = clonedProd.qty * clonedProd.unitCost + clonedProd.deliveryFee + clonedProd.packaging;
                    clonedProd.totalAmt = clonedProd.qty * clonedProd.unitCost;
                }

                totalcost += clonedProd.totalAmt
                return clonedProd
            }

            totalcost += prod.totalAmt;

            return prod;
        })

        setProducts(newProducts);
        setTotalAmtBeforeGST(totalcost)
    }

    const handleDeliveryInputChange = (event) => {
        setEdited(true);

        const inputFieldValue = event.target.value;
        const inputFieldName = event.target.name;

        let newInputValue = { ...formDeliveryInputData, [inputFieldName]: inputFieldValue };

        setFormDeliveryInputData(newInputValue);
    }

    const onSubmitHandler = () => {
        const id = order._id;

        const submittingOrder = {
            status: formHeaderInputData.status,
            xeroInvoiceId: formHeaderInputData.xeroInvoiceId,
            paymentStatus: formHeaderInputData.paymentStatus,
            ...formOverviewInputData,
            client,
            products,
            delivery: formDeliveryInputData,
            totalAmtBeforeGST,
            revision: order.revision
        }

        dispatch(updateOrder(id, submittingOrder));
    }

    const onalertClose = () => {
        setOpenSnackBar(false)
        dispatch(orderActions.Reset_Update_Order_State());

        if (deleteSuccessRes) {
            navigate('/order')
        }
    }

    return (
        <>
            {isUpdating && <Loading loadingText="" style={{ width: '100%' }} />}

            <div className={styles.container}>
                {
                    hasProductError &&
                    <div className={styles['error-banner']}>
                        {productErr || imageErr || deleteErrMsg || hasSalesRepOptionErr || hasOptionErr}
                    </div>
                }
                {
                    order.xeroPendingSync &&
                    <div className={styles['error-banner']} style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                        <SyncProblem fontSize='small' />
                        Order is out of sync with Xero Invoice.
                        
                        <Button 
                            size='small' 
                            variant="contained" 
                            endIcon={<CloudSync/>}
                            sx={{fontSize:'0.6rem'}}
                            onClick={() => dispatch(syncXeroInvoiceToOrder(order))}>
                            Sync
                        </Button>
                    </div>
                }
                <FormHeader
                    formData={formHeaderInputData}
                    handleInputChange={handleFormHeaderInputChange}
                    submitHandler={onSubmitHandler}
                    validform={validForm}
                    order={order} />
                <FormOverview
                    formHeader={formHeaderInputData}
                    formData={formOverviewInputData}
                    handleInputChange={handleFormOverviewInputChange} />
                <Divider />
                <ProductionSheet
                    formHeader={formHeaderInputData}
                    clientFormData={client}
                    productFormData={products}
                    deliveryFormData={formDeliveryInputData}
                    handleClientInputChange={handleClientInputChange}
                    handleProductInputChange={handleProductInputChange}
                    handleDeliveryInputChange={handleDeliveryInputChange}
                    overallcost={totalAmtBeforeGST} />

                {
                    hasProductError &&
                    <div className={styles['error-banner']}>
                        {productErr || imageErr}
                    </div>
                }
            </div>

            <ToastAlert
                open={openSnackbar}
                onClose={onalertClose}
                message={snackMessage}
                type={snackType}
            />
        </>
    )
}

export default OrderForm