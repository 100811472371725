import ReactDOM from "react-dom";
import React, { useState } from "react";

import DropFileInput from "./drop-file-input";
import styles from "./ManageUpload.module.css";

import IconButton from "@mui/material/IconButton";
import { Close } from "@mui/icons-material";
import FileUploadItem from "./FileUploadItem";
import { useDispatch, useSelector } from "react-redux";
import uuid from "react-uuid";

import { onImageUpload } from "../../store/order-actions";

const UploadWidget = (props) => {

    const { product } = props;

    const dispatch = useDispatch();

    const order = useSelector(state => state.order.order);

    const [imagesInUploading, setImagesInUploading] = useState([])

    const exceedLimit = product.cld_images.length >= 3;

    const fileAddHandler = (file) => {
        let reader = new FileReader();

        reader.onload = function (e) {
            var name = file.name;
            name = name.replace(/\.(png|jpg|jpeg)$/i, "");
            name = name.replace(/(\s|\+)/g, "_");
            name = name.replace(/(\(|\))/g, "");

            const imageItem = {
                id: uuid(),
                productid: product.id,
                orderid: order._id,
                imageUri: e.target.result,
                name,
                size: file.size,
                axiosController: new AbortController()
            };

            setImagesInUploading([...imagesInUploading, imageItem])

            
            //dispatch(uploadImage(props.productId, imageItem))
        };

        reader.readAsDataURL(file);
    };

    const onImageDelete = (productId, cldimage) => { 
        const updatedlist = imagesInUploading.filter(u => u.name !== cldimage.public_id)
        setImagesInUploading(updatedlist);
        dispatch(onImageDelete(productId, cldimage.asset_id));
    };

    const onImageUploaded = (cldimage) => {
        dispatch(onImageUpload(product.id, cldimage))
    };

    const onUploadCancel = (uploaditem) => {
        const uploadingimg = imagesInUploading.find(i => i.id === uploaditem.id);

        uploadingimg.axiosController.abort();
        uploadingimg.axiosController = new AbortController();
    }

    const closeHandler = () => {
        imagesInUploading.forEach(i => i.axiosController.abort())
        props.onClose();
    }

    return (
        <div className={styles.backdrop}>
            <div className={styles.wrapper}>
                <div className={styles.header}>
                    <span>MANAGE IMAGES</span>
                    <IconButton aria-label="close" onClick={closeHandler}>
                        <Close />
                    </IconButton>
                </div>
                <div className={styles.content}>
                    <DropFileInput
                        disabled={exceedLimit}
                        onFileAdd={fileAddHandler}
                    />
                    <div className={styles["file-upload-item-container"]}>
                        {imagesInUploading.map(uploadingImg => (
                            <FileUploadItem 
                                key={uploadingImg.id} 
                                uploaditem={uploadingImg}
                                onUploadCancel={onUploadCancel}
                                onImageUploaded={onImageUploaded}
                                onImageDelete={onImageDelete} />
                        ))}
                    </div>
                    <span style={{fontSize:'0.8rem', fontWeight:'bold', textAlign:'center'}}>
                        Cautious, closing while uploading will terminate the uploading process
                    </span>
                </div>
            </div>
        </div>
    );
};

const ManageUpload = (props) => {
    return ReactDOM.createPortal(
        <UploadWidget
            productId={props.productId}
            product={props.product}
            onSubmit={props.onSubmit}
            onClose={props.onClose}
        />,
        document.getElementById("overlay-root")
    );
};

export default ManageUpload;
