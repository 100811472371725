import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import ErrorPage from '../../ErrorPage';
import Loading from '../../../Layout/Loading';
import { getOrder } from '../../../store/order-actions';
import { getOptions } from '../../../store/option-actions';
import { getUsers } from '../../../store/user-actions';

import styles from './Order.module.css';

import OrderForm from './OrderForm/OrderForm';
import XeroSection from './XeroSection';
import { getContacts } from '../../../store/xero-actions';

const Order = () => {
    const dispatch = useDispatch();

    //typo, this is ID
    const { orderno } = useParams();

    const authUser = useSelector(state => state.auth.authUser);
    const isLoadingOrder = useSelector(state => state.order.req_order_loading);
    const order = useSelector(state => state.order.order);
    const reqError = useSelector(state => state.order.req_order_error);

    const [selectedXeroItem, setSelectedXeroItem] = useState( null);

    useEffect(() => {
        dispatch(getOrder(orderno))
        dispatch(getOptions('FormOptions'))
        dispatch(getUsers(['_id','firstname','lastname','contact']))
        
        if(!!authUser.xero){
            dispatch(getContacts())
        }
        
        //eslint-disable-next-line
    }, [])
    
    const onXeroItemSelected = (xeroitem) => {
        setSelectedXeroItem(xeroitem);
    }

    const renderNoXero = () => {
        return (
            <div className={styles['noplugin-wrapper']}>
                <header>Xero Plugin</header>
                <section>
                    Link your xero account to search and copy contact deta_ils to your order form.
                    To get started, go to&nbsp;
                    <Link to="/profile#xero">Profile</Link>.
                </section>
            </div>
        )
    }

    return (
        <div className={styles.main}>
            <section className={styles['order-section']}>

                {
                    !isLoadingOrder && order && 
                    <OrderForm order={order} xeroitem={selectedXeroItem} />
                }

                {
                    !isLoadingOrder && !order &&
                    <div className={styles['error-wrapper']}>
                        <ErrorPage>
                            <span>
                                {reqError}
                            </span>
                        </ErrorPage>
                    </div>
                }
                {
                    isLoadingOrder &&
                    <div style={{ marginTop: '48px' }}>
                        <Loading loadingText="Retrieving order" style={{ width: '70%' }} />
                    </div>

                }
            </section>
            <section className={styles['xero-section']}>
                {
                    !!authUser.xero &&
                    <XeroSection onXeroItemSelected={onXeroItemSelected} />
                }
                {
                    !authUser.xero &&
                    renderNoXero()
                }
            </section>
        </div>
    )
}

export default Order