
import { xeroActions } from "./xero-slice";
import { orderActions } from "./order-slice";
import { xeroApi } from "../Api";

import { updateAuthUser } from "./auth-actions";

export const getContact = (contactid) => {
    return async (dispatch) => {
        dispatch(xeroActions.Request_Contact())

        xeroApi.get(`/contacts/${contactid}`)
            .then(response => {
                dispatch(xeroActions.Request_Contact_Response(response.data))
            }).catch(err => {
                dispatch(xeroActions.Request_Contact_Error(err.response.data.message));

                if (err.response.data.message === 'invalid_grant') {
                    dispatch(updateAuthUser({ xero: null }));
                }
            })
    }
}

export const getContacts = () => {

    return async (dispatch) => {
        dispatch(xeroActions.Request_Contacts())

        xeroApi.get('/contacts')
            .then(response => {
                dispatch(xeroActions.Request_Contacts_Response(response.data.Contacts))
            }).catch(err => {
                dispatch(xeroActions.Request_Contacts_Error(err.response.data.message));

                if (err.response.data.message === 'invalid_grant') {
                    dispatch(updateAuthUser({ xero: null }));
                }
            })
    }
}

export const syncXeroInvoiceToOrder = (order) => {
    return async (dispatch) => {
        dispatch(orderActions.Request_Order());

        xeroApi.post(`/sync-order-to-xero/${order._id}`)
            .then(response => {
                dispatch(orderActions.Request_Order_Response({ data: response.data, err: null }))
            }).catch(error => {
                dispatch(orderActions.Request_Order_Response({ data: null, err: error.response.data.message }))
            })
    }
}