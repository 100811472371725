import React, { useEffect, useState } from 'react'
import styles from './LoginPage.module.css';
import Footer from '../../Layout/Footer';

import logo from '../../assets/logo/trea-logo.png';

import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import { Button } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { authenticate } from '../../store/auth-actions';

const bannerTitle = 'TREA Ordering Management System'

const LoginPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const authUser = useSelector(state => state.auth.authUser);
    const isAuthenticating = useSelector(state => state.auth.req_loading);
    const reqError = useSelector(state => state.auth.req_error)

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const [rememberMe, setRememberMe] = useState(!!localStorage.getItem('savedEmail'))

    const loginHandler = (e) => {
        e.preventDefault();

        if (email !== '' && password !== '') {
            if(rememberMe){
                localStorage.setItem('savedEmail', email);
            }else{
                setRememberMe(localStorage.removeItem('savedEmail'))
            }
            dispatch(authenticate(email, password));
        }
    }

    useEffect(() => {
        if (authUser) {
            navigate('/')
        }
        //eslint-disable-next-line
    }, [authUser])

    useEffect(() => {
        if(localStorage.getItem('savedEmail')){
            setEmail(localStorage.getItem('savedEmail'))
        }
    },[])

    return (
        <form className={styles.main} onSubmit={loginHandler}>
            <header className={styles.header}>
                {bannerTitle}
            </header>
            <section className={styles.section}>
                <img src={logo} alt="logo" className={styles.img}></img>
                <hr className={styles.hr} />
                <FormControl variant="standard" className={styles.form}>
                    <TextField
                        label="Email"
                        id="email"
                        name="email"
                        variant="standard"
                        value={email}
                        onChange={(e) => { setEmail(e.target.value) }}
                        sx={{ marginBottom: '16px' }}
                    />

                    <TextField
                        label="Password"
                        id="password"
                        variant="standard"
                        name="password"
                        type="password"
                        value={password}
                        onChange={(e) => { setPassword(e.target.value) }}
                        sx={{ marginBottom: '16px' }}
                    />

                    <FormControlLabel 
                        control={<Checkbox 
                            color="success"
                            checked={rememberMe}
                            onChange={(e) => setRememberMe(e.target.checked)} />} 
                        label="Remember Me" />

                    <Button variant="contained" color="success" type="submit" disabled={isAuthenticating}>
                        Log In
                    </Button>
                    {
                        !!reqError && <p style={{ color: 'red' }}>{reqError ?? 'Undefined Error'}</p>
                    }
                </FormControl>
            </section>
            <Footer />
        </form>
    )
}

export default LoginPage