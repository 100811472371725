import React from 'react';

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ToastAlert = (props) => {

    const { onClose, open, type, message } = props;

    let msg = message;

    if(!msg){
        if(type === 'success'){
            msg = 'Operation success!'
        }else if(type === 'error'){
            msg = 'Operation failed!'
        }
    }

    return (
        <Snackbar 
            open={open} 
            autoHideDuration={3000} 
            onClose={onClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>

            <Alert onClose={onClose} severity={type} sx={{ width: '100%' }}>
                {msg}
            </Alert>
        </Snackbar>
    )
}

export default ToastAlert