import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

import { BrowserRouter } from 'react-router-dom';

import { Provider } from 'react-redux';
import store from './store';

const root = ReactDOM.createRoot(document.getElementById('root'));

//below is causing component to render twice, so took it out.
//<React.StrictMode>

root.render(
  <BrowserRouter>
    <Provider store={store}><App /></Provider>
  </BrowserRouter>
);