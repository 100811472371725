import { useState } from 'react'

const UserInput = (validateFunction) => {
  const [enteredValue, setEnteredValue] = useState('');
  const [isTouched, setIsTouched] = useState(false);

  let valueIsValid = !!validateFunction ? validateFunction(enteredValue) : true;
  const hasError = !!validateFunction ? !valueIsValid && isTouched : false;

  const valueChangeHandler = event => {
    setEnteredValue(event.target.value);
  };

  const inputBlurHandler = event => {
    setIsTouched(true);
  }

  const reset = () => {
    setEnteredValue('');
    setIsTouched(false);
  }

  return {
    value: enteredValue,
    isValid: valueIsValid,
    reset,
    hasError,
    valueChangeHandler,
    inputBlurHandler
  }
}

export default UserInput