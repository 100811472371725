import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './FormHeader.module.css';

import Typography from '@mui/material/Typography';
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Divider from '@mui/material/Divider';
import Menu from '@mui/material/Menu';
import { useParams } from 'react-router-dom';
import { getOrder, deleteOrder, downloadCustomerPdf, downloadInternalPdf, previewCustomerPdf, previewInternalPdf } from '../../../../store/order-actions';
import { Link } from '@mui/icons-material';
import LinearProgress from "@mui/material/LinearProgress";

import ConfirmationDialog from '../../../../UI/Alert/ConfirmDialog';

const buttonStyle = {
    color: '#292929',
    border: '1px solid #292929'
}

const saveButtonStyle = {
    color: '#2E7D32',
    border: '1px solid #2E7D32'
}

const menuPaperProps = {
    elevation: 0,
    sx: {
        overflow: 'visible',
        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
        mt: 1.5,
        '& .MuiAvatar-root': {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
        },
        '&:before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: 'background.paper',
            transform: 'translateY(-50%) rotate(45deg)',
            zIndex: 0,
        },
    },
}

const statuses = ['Draft', 'Sent', 'Cancelled', 'Approved'];
const paymentStatuses = [
    'To Invoice',
    'Awaiting Payment',
    'Partial Payment',
    'Overdue',
    'Paid'
]

const FormHeader = (props) => {

    //typo, this is ID
    const { orderno } = useParams();
    const dispatch = useDispatch();
    //const navigate = useNavigate();

    /**
     * Only saved order will reflect changes in the availability of delete and
     * downloading of pdfs.
     */
    const order = useSelector(state => state.order.order);
    const isUpdating = useSelector(state => state.order.req_order_update_loading);
    const isDownloading = useSelector(state => state.order.req_order_loading);
    const isDeleting = useSelector(state => state.order.req_order_delete_loading);

    const [downloadMenuAnchor, setDownloadMenuAnchor] = useState(false);
    const downloadMenuOpen = Boolean(downloadMenuAnchor);

    const [previewMenuAnchor, setPreviewMenuAnchor] = useState(false);
    const previewMenuOpen = Boolean(previewMenuAnchor);

    const [statusMenuAnchor, setStatusMenuAnchor] = useState(false);
    const statusMenuOpen = Boolean(statusMenuAnchor);

    const [showDeleteDialog, setShowDeleteDialog] = useState(false);

    const internalPDFDownloadable = order.status !== 'Draft';

    const isPreviewingCustomerPDF = useSelector(state => state.order.isPreviewingCustomerPDF);
    const isPreviewingInternalPDF = useSelector(state => state.order.isPreviewingInternalPDF);

    const isDownloadingCustomerPDF = useSelector(state => state.order.isDownloadingCustomerPDF);
    const isDownloadingInternalPDF = useSelector(state => state.order.isDownloadingInternalPDF);

    const [pdfModeType, setPdfModeType] = useState(null);

    const isLoadingPDF = isPreviewingCustomerPDF || isPreviewingInternalPDF ||
        isDownloadingCustomerPDF || isDownloadingInternalPDF

    const refreshHandler = () => {
        dispatch(getOrder(orderno));
    }

    const deleteOrderHandler = () => {
        setShowDeleteDialog(false);

        dispatch(deleteOrder(orderno));
    }

    const previewCustomerPDFHandler = () => {
        setPdfModeType('preview')
        dispatch(previewCustomerPdf(orderno))
    }

    const previewInternalPDFHandler = () => {
        setPdfModeType('preview')
        dispatch(previewInternalPdf(orderno))
    }

    const downloadCustomerCopyHandler = () => {
        setPdfModeType('download')
        dispatch(downloadCustomerPdf(orderno))
    }

    const downloadInternalCopyHandler = () => {
        setPdfModeType('download')
        dispatch(downloadInternalPdf(orderno))
    }

    const getPaymentStatusClassName = (status) => {
        switch (status) {
            case 'Awaiting Payment':
                return styles['awaiting-payment-status'];
            case 'Partial Payment':
                return styles['partial-payment-status'];
            case 'To Invoice':
                return styles['to-invoice-status'];
            case 'Overdue':
                return styles['overdue-status'];
            case 'Paid':
                return styles['paid-status'];
            default:
                return null;
        }
    }

    const changePaymentStatusHandler = (status) => {
        if (!order) { return; }

        props.handleInputChange({ target: { name: 'paymentStatus', value: status } })
    }

    const paymentStatusMenuHandler = (e) => {

        if (!order) { return; }

        if (order.xeroInvoiceId !== '') {
            //Means there is xero invoice linked. That case we do not allow payment status change.
            return;
        }

        setStatusMenuAnchor(e.currentTarget)
    }

    return (
        <>
            <div className={styles.container}>
                <div className={styles.header}>
                    <div style={{ flex: '1 1 auto', display: 'flex' }}>
                        {props.formData.binded && <Link sx={{ alignSelf: 'flex-end', fontSize: '1.1rem', color: 'green', marginRight: '4px' }} />}
                        <Typography variant="h4" sx={{ flex: '0 0 auto' }}>
                            {props.formData.orderNo}
                        </Typography>
                        {
                            props.formData.status !== 'Cancelled' &&
                            <div
                                className={`${styles['payment-status-chip']} ${getPaymentStatusClassName(props.formData.paymentStatus)}`}
                                onClick={paymentStatusMenuHandler}>
                                {props.formData.paymentStatus}
                            </div>
                        }
                        <div style={{ flex: '1 1 auto' }}></div>
                    </div>
                    {/* Status */}
                    <FormControl size="small">
                        <InputLabel id="select-status">Status</InputLabel>
                        <Select
                            labelId="select-status"
                            id="select-status"
                            value={props.formData.status}
                            label="Status"
                            size='small'
                            name="status"
                            onChange={props.handleInputChange}>
                            {statuses.map((status) => {
                                return <MenuItem key={status} value={status}>{status}</MenuItem>
                            })}
                        </Select>
                    </FormControl>

                </div>

                <div className={styles['modified-section']}>
                    <div className={styles['modified-section-lbl']}>
                        <span>Created: </span>
                        <span>Created by: </span>
                        <span>Last modified: </span>
                        <span>Modified by: </span>
                    </div>
                    <div className={styles['modified-section-user']}>
                        <span>{props.formData.createdAt}</span>
                        <span>{props.formData.salesrep}</span>
                        <span>{props.formData.updatedAt}</span>
                        <span>{props.formData.updatedBy}</span>
                    </div>
                </div>

                <div className={styles['button-section']}>
                    <ButtonGroup size="small" aria-label="small button group" >
                        <Button
                            key="2"
                            sx={saveButtonStyle}
                            onClick={props.submitHandler}
                            disabled={!props.validform || isUpdating || isDeleting}>Save</Button>
                        <Button
                            key="1"
                            sx={buttonStyle}
                            onClick={(e) => setPreviewMenuAnchor(e.currentTarget)}>Preview</Button>
                        <Button
                            key="3"
                            sx={buttonStyle}
                            onClick={(e) => setDownloadMenuAnchor(e.currentTarget)}>Download</Button>
                        <Button
                            key="4"
                            sx={buttonStyle}
                            disabled={isDownloading || isDeleting}
                            onClick={refreshHandler}>Refresh</Button>
                    </ButtonGroup>
                    <div style={{ flex: '1 1 auto' }}></div>
                    <Button
                        variant="outlined"
                        color="error"
                        size='small'
                        onClick={() => setShowDeleteDialog(true)}>
                        Delete
                    </Button>
                </div>
                {
                    isLoadingPDF &&
                    <div style={{ display: 'flex', flexDirection: 'column', fontSize: '0.75rem' }}>
                        {pdfModeType === 'download' ? 'Downloading ' : 'Previewing '} PDF..
                        <LinearProgress variant='indeterminate' />
                    </div>
                }
            </div>

            <ConfirmationDialog
                open={showDeleteDialog}
                onCancel={() => { setShowDeleteDialog(false) }}
                onAccept={deleteOrderHandler}
                title="Delete Confirmation"
                content={`Confirm delete order? Once deleted, it will not be retrievable.`}
            />

            {/* Download Menu */}
            <Menu
                anchorEl={downloadMenuAnchor}
                id="account-menu"
                open={downloadMenuOpen}
                onClose={() => setDownloadMenuAnchor(false)}
                onClick={() => setDownloadMenuAnchor(false)}
                PaperProps={menuPaperProps}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>

                <MenuItem onClick={downloadCustomerCopyHandler}>
                    Customer Copy
                </MenuItem>
                <Divider />
                <MenuItem onClick={downloadInternalCopyHandler} disabled={!internalPDFDownloadable}>
                    In-house Copy
                </MenuItem>
            </Menu>

            {/* Preview Menu */}
            <Menu
                anchorEl={previewMenuAnchor}
                id="account-menu"
                open={previewMenuOpen}
                onClose={() => setPreviewMenuAnchor(false)}
                onClick={() => setPreviewMenuAnchor(false)}
                PaperProps={menuPaperProps}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>

                <MenuItem onClick={previewCustomerPDFHandler}>
                    Customer Copy
                </MenuItem>
                <Divider />
                <MenuItem onClick={previewInternalPDFHandler}>
                    In-house Copy
                </MenuItem>
            </Menu>

            {/* Payment status Menu */}
            <Menu
                anchorEl={statusMenuAnchor}
                id="payment-status-menu"
                open={statusMenuOpen}
                onClose={() => setStatusMenuAnchor(false)}
                onClick={() => setStatusMenuAnchor(false)}
                PaperProps={menuPaperProps}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
                {
                    paymentStatuses
                        .map(status =>
                            <MenuItem onClick={() => changePaymentStatusHandler(status)} key={status}>
                                {status}
                            </MenuItem>)
                }
            </Menu>
        </>
    )
}

export default FormHeader