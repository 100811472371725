import React, { useEffect, useState } from 'react';

import Subheader from '../Base/Subheader';

import styles from './User.module.css';

import IconButton from '@mui/material/IconButton';
import { Add, Refresh, PersonSearch, Delete, Lock, LockOpen } from '@mui/icons-material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import RenderAddUserModal from '../../../UI/Card/AddUserModal';
import ConfirmationDialog from '../../../UI/Alert/ConfirmDialog';

import { getUsers, updateUser, deleteUser, resetAddUserState } from '../../../store/user-actions';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const User = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [showModal, setShowModal] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  const authUser = useSelector(state => state.auth.authUser);
  const users = useSelector(state => state.user.users);
  const isLoadingUser = useSelector(state => state.user.req_loading);

  const [userForDeletion, setUserForDeletion] = useState(null);

  useEffect(() => { 
    dispatch(getUsers());
    //eslint-disable-next-line
  }, [])

  const addUserHandler = () => { setShowModal(true); }

  const onNewUserAdded = (user) => {
    //dispatch(addUser(user));
    dispatch(resetAddUserState())
    setShowModal(false);
  }

  const onCloseModal = () => { setShowModal(false); }

  const refreshHandler = () => {
    if (isLoadingUser) {
      return;
    }

    dispatch(getUsers());
  }

  const onDisableUser = (user, disabled) => {
    const id = user._id;

    //To deal with lag or bug. Or if previously already send req but not yet return
    if (user.disabled === disabled || isLoadingUser) {
      return;
    }

    dispatch(updateUser(id, { disabled }))
  }

  const onDeleteUser = () => {
    setShowDeleteDialog(false);

    dispatch(deleteUser(userForDeletion))

    setUserForDeletion(null);
  }

  const onDeleteUserClicked = (user) => {
    setUserForDeletion(user);
    setShowDeleteDialog(true);
  }

  return (
    <>
      {showModal && <RenderAddUserModal onSubmit={onNewUserAdded} onClose={onCloseModal} />}

      <div className={styles.main}>
        <Subheader title="Users" />

        <div className={styles['body-wrapper']}>
          <section>
            Create new users who will be able to access TREA Order Management Program. Users with standard
            rights cannot view users and can only edit certain fields.
          </section>
          <div className={styles.action}>
            <IconButton aria-label="add" size="small" onClick={addUserHandler}>
              <Add fontSize='inherit' />
            </IconButton>
            <IconButton
              onClick={refreshHandler}
              aria-label="refresh"
              size="small">
              <Refresh fontSize='inherit' />
            </IconButton>
          </div>

          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="user table">
              <TableHead sx={{ backgroundColor: '#84DADE' }}>
                <TableRow>
                  <TableCell sx={{ border: '1px solid black', width: '150px' }}>Name</TableCell>
                  <TableCell sx={{ border: '1px solid black', width: '250px' }} align="left">Email</TableCell>
                  <TableCell sx={{ border: '1px solid black', width: '100px' }} align="left">Role</TableCell>
                  <TableCell sx={{ border: '1px solid black' }} align="left">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {users.map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row.displayname}
                    </TableCell>
                    <TableCell align="left">{row.email}</TableCell>
                    <TableCell align="left">{row.role}</TableCell>
                    <TableCell align="left" className={styles.action}>
                      <IconButton aria-label="view" onClick={() => navigate(row._id)}>
                        <PersonSearch />
                      </IconButton>
                      {
                        row.disabled && <IconButton aria-label="lock" onClick={() => { onDisableUser(row, false) }}>
                          <Lock sx={{ color: 'red' }} />
                        </IconButton>
                      }
                      {
                        !row.disabled && <IconButton aria-label="unlock" onClick={() => { onDisableUser(row, true) }}>
                          <LockOpen sx={{ color: 'green' }} />
                        </IconButton>
                      }
                      {
                        row.role !== 'Owner' &&
                        row.email !== authUser.email &&
                        <IconButton aria-label="delete" onClick={() => onDeleteUserClicked(row)}>
                          <Delete sx={{ color: 'red' }} />
                        </IconButton>
                      }
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>

      <ConfirmationDialog
        open={showDeleteDialog}
        onCancel={() => { setShowDeleteDialog(false) }}
        onAccept={onDeleteUser}
        title="Delete Confirmation"
        content={`Confirm delete ${userForDeletion?.firstname}?`}
      />
    </>
  )
}

export default User