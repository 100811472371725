import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import styles from './UserProfileVer2.module.css';
import UserForm from './UserForm';
import ToastAlert from '../../../UI/Alert/ToastAlert';
import { userActions } from '../../../store/user-slice';
import { updateUser } from '../../../store/user-actions';
import { refreshProfile } from '../../../store/auth-actions';

const UserProfileVer2 = (props) => {
    const dispatch = useDispatch();

    const authUser = useSelector(state => state.auth.authUser);
    const isUpdatingUser = useSelector(state => state.user.req_update_loading);
    const reqUpdateResponse = useSelector(state => state.user.req_update_response);
    const reqUpdateError = useSelector(state => state.user.req_update_error);

    //Alert state
    const [openSnackbar, setOpenSnackBar] = useState(false);
    const [snackMessage, setSnackMessage] = useState('');
    const [snackType, setSnackType] = useState('success');

    useEffect(() => {
        dispatch(refreshProfile());
        //eslint-disable-next-line
    },[])

    useEffect(() => {
        if(reqUpdateResponse){
            displayAlert(true)
            dispatch(userActions.Clear_Errors());
        }

        if(reqUpdateError){
            displayAlert(false, reqUpdateError)
            dispatch(userActions.Clear_Errors());
        }
        //eslint-disable-next-line
    }, [reqUpdateResponse, reqUpdateError])

    const saveHandler = (data) => {
        dispatch(updateUser(authUser._id, data, true))
    }

    function displayAlert(isSuccess, snackMsg='Save success!') {
        setSnackType(isSuccess ? 'success':'error');
        setSnackMessage(snackMsg);
        setOpenSnackBar(true)
    }

    return (
        <div className={styles.main}>
            <header className={styles['title-section']}>
                <h1>Account settings</h1>
                <p>Change your profile and account settings</p>
            </header>
            {
                !!authUser &&
                <UserForm 
                    user={authUser}
                    onSave={saveHandler}
                    viewOnly={false}
                    isUpdating={isUpdatingUser}
                />
            }
            <ToastAlert
                open={openSnackbar}
                onClose={() => { setOpenSnackBar(false) }}
                message={snackMessage}
                type={snackType}
            />
        </div>
    )
}

export default UserProfileVer2