

import LoginPage from './Pages/Login/LoginPage';
import Base from './Pages/Private/Base/Base';
import Dashboard from './Pages/Private/Dashboard/Dashboard';
import User from './Pages/Private/User/User';
import Order from './Pages/Private/Order/Order';
import PDFBlock from './UI/PDF/PDFBlock';
//import ErrorPage from './Pages/ErrorPage';
import UserProfileVer2 from './Pages/Private/User/UserProfileVer2';
import UserDetail from './Pages/Private/User/UserDetail';

import { Routes, Route, Navigate, Outlet } from 'react-router-dom';

import React, { useEffect } from 'react';

import ProtectedRoute from './Pages/Private/Base/ProtectedRoute';
import { useDispatch, useSelector } from 'react-redux';

import { autologin } from './store/auth-actions';

function App() {
  const authUser = useSelector(state => state.auth.authUser);
  const dispatch = useDispatch();

  //const [initOnce, setInitOnce] = useState(true);

  useEffect(() => {
    // if (!authUser) {
    //   dispatch(autologin(() => {
    //     setInitOnce(true);
    //   }))
    // }

    dispatch(autologin())
    
    //eslint-disable-next-line
  }, [])

  const privateRoutes = [
    <Route key="home" index element={<Dashboard />} />,
    <Route key="order" path="order/:orderno" element={<Order />} />,
    <Route key="orderpdf" path="order/:orderno/pdf" element={<PDFBlock />} />,
    <Route key="profile" path="profile" element={<UserProfileVer2 />} />
  ]

  if(authUser?.role !== 'Standard'){
    privateRoutes.push(<Route key="user" path="user" element={<User />} />);
    privateRoutes.push(<Route key="userdetail" path="user/:id" element={<UserDetail />} />)
  }

  return (
    <>
      {
        <>
          <Outlet />
          <Routes>
            <Route
              key="base"
              path="/"
              element={<ProtectedRoute user={authUser}><Base /></ProtectedRoute>}
              children={privateRoutes} />

            <Route key="login" path="/login" element={<LoginPage />} />
            <Route key="fallback" path="*" element={<Navigate to="/" />} />
          </Routes>
        </>
      }
    </>
  )
}

export default App;
