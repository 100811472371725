
import { optionActions } from "./option-slice";
import { OptionApiV2 } from "../Api";

export const getOptions = (type) => {
    return async (dispatch) => {

        dispatch(optionActions.Request_Options());

        const apiCall = !!type ? `?type=${type}` : '';

        OptionApiV2.get(apiCall).then(response => {
            dispatch(optionActions.Request_Options_Response({
                data: response.data,
                err: null
            }))
        }).catch(err => {
            dispatch(optionActions.Request_Options_Response({
                data: null,
                err: err.response.data.message
            }))
        })
    }
}