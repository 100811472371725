import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import styles from './UserDetail.module.css';
import UserForm from './UserForm';
import ToastAlert from '../../../UI/Alert/ToastAlert';
import { userActions } from '../../../store/user-slice';
import { updateUser, getUser } from '../../../store/user-actions';
import Loading from '../../../Layout/Loading';
import ErrorPage from '../../ErrorPage';

import format from 'date-fns/format';

const UserDetail = () => {
    const dispatch = useDispatch();

    const params = useParams();

    const authUser = useSelector(state => state.auth.authUser);
    const reqUser = useSelector(state => state.user.user);
    const isRequestingUser = useSelector(state => state.user.req_user_loading);
    const reqUserError = useSelector(state => state.user.req_user_error);

    const isUpdatingUser = useSelector(state => state.user.req_update_loading);
    const reqUpdateResponse = useSelector(state => state.user.req_update_response);
    const reqUpdateError = useSelector(state => state.user.req_update_error);

    //Alert state
    const [openSnackbar, setOpenSnackBar] = useState(false);
    const [snackMessage, setSnackMessage] = useState('');
    const [snackType, setSnackType] = useState('success');

    useEffect(() => {
        dispatch(getUser(params.id));
        //eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (reqUpdateResponse) {
            displayAlert(true)
            dispatch(userActions.Clear_Errors());
        }

        if (reqUpdateError) {
            displayAlert(false, reqUpdateError)
            dispatch(userActions.Clear_Errors());
        }
        //eslint-disable-next-line
    }, [reqUpdateResponse, reqUpdateError])

    const saveHandler = (data) => {
        dispatch(updateUser(reqUser._id, data, authUser._id === reqUser._id))
    }

    function displayAlert(isSuccess, snackMsg = 'Save success!') {
        setSnackType(isSuccess ? 'success' : 'error');
        setSnackMessage(snackMsg);
        setOpenSnackBar(true)
    }

    return (
        <div className={styles.main}>

            {
                !isRequestingUser && reqUserError &&
                <div className={styles['error-wrapper']}>
                    <ErrorPage>
                        <span>
                            {reqUserError}
                        </span>
                    </ErrorPage>
                </div>
            }
            {
                isRequestingUser &&
                <div style={{ marginTop: '48px' }}>
                    <Loading loadingText="Retrieving user" style={{ width: '70%' }} />
                </div>
            }

            {
                !!reqUser &&
                <>
                    <header className={styles['title-section']}>
                        <h1>{`${reqUser.firstname} ${reqUser.lastname}`}</h1>
                        <section className={styles['activity-stat']}>
                            <div>
                                <span>Created at: </span>
                                <span>Modified at: </span>
                            </div>
                            <div>
                                <span>{format(new Date(reqUser.createdAt), 'dd/MM/yy hh:mm a')}</span>
                                <span>{format(new Date(reqUser.updatedAt), 'dd/MM/yy hh:mm a')}</span>
                            </div>
                        </section>
                    </header>

                    <UserForm
                        user={reqUser}
                        onSave={saveHandler}
                        viewOnly={reqUser.role === 'Owner' || reqUser.role === 'SysInternal'}
                        isUpdating={isUpdatingUser}
                    />
                </>
            }

            <ToastAlert
                open={openSnackbar}
                onClose={() => { setOpenSnackBar(false) }}
                message={snackMessage}
                type={snackType}
            />
        </div>
    )
}

export default UserDetail