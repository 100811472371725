import React from 'react';
import styles from './DeliveryItem.module.css';

// import Select from '@mui/material/Select';
// import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';

import dayjs from 'dayjs';

const inputStyle = {
    "& .MuiInputBase-root": {
        height: 30,
        padding: 0
    },
    "& .MuiOutlinedInput-root.MuiInputBase-sizeSmall": {
        padding: 0
    },
    width: '100%'
}

function toDateInputFormat(date) {
    return date ? dayjs(date).format('YYYY-MM-DD') : ''
  }

const DeliveryItem = (props) => {
    const { onInputChange, delivery } = props;

    // const options = useSelector(state => state.option.options);
    // const optionLoading = useSelector(state => state.option.req_options_loading);

    // const [statusOptions, setStatusOptions] = useState([delivery.status])

    // useEffect(() => {
    //     if (options) {
    //         const statusObj = options.find(obj => obj.name === 'delivery_status')

    //         if (statusObj) {
    //             setStatusOptions(statusObj.value)
    //         }
    //     }
    //     //eslint-disable-next-line
    // }, [optionLoading])

    return (
        <div className={styles.wrapper}>
            <div className={styles['input-row']}>
                {/* <div className={styles['input-field-group']} style={{ flex: '0 1 50%' }}>
                    Delivery Status
                    <Select
                        id="status"
                        name="status"
                        sx={inputStyle}
                        style={{ height: 30 }}
                        disabled={optionLoading}
                        value={delivery.status}
                        onChange={onInputChange}
                    >
                        {
                            statusOptions.map(opt => {
                                return <MenuItem key={opt} value={opt}>
                                    {opt}
                                </MenuItem>
                            })
                        }
                    </Select>
                </div> */}
                <div className={styles['input-field-group']} style={{ flex: '0 1 50%' }}>
                    Delivery Date
                    <TextField
                        id="date"
                        name="date"
                        variant="outlined"
                        size='small'
                        type="date"
                        sx={inputStyle}
                        value={toDateInputFormat(delivery.date)}
                        onChange={onInputChange} />
                </div>
            </div>
            {/* <div className={styles['input-field-group']}>
                Delivery Notes
                <TextField
                    id="note"
                    name="note"
                    size='small'
                    multiline
                    rows={3}
                    value={delivery.note}
                    onChange={onInputChange} />
            </div> */}
        </div>
    )
}

export default DeliveryItem