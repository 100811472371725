import { createSlice } from '@reduxjs/toolkit';
//import { OrderConstant } from '../Constants';

const initialState = {
    order: null,

    //to delete once order api fully ported to v2
    orders: [],

    //Version 2 Orders Detail, support pagination
    ordersV2: [],
    currentRequestPage: 1,
    totalOrderCount: 0,

    /**
     * status: null,
        searchTerm: null,
        startDate: null,
        endDate: null
     */
    filteredOrdersQuery:null,
    req_orders_v2_loading: false,
    req_orders_v2_error: null,
    req_orders_v2_response: null,

    //to delete once order api fully ported to v2
    req_orders_loading: false,
    req_orders_error: null,
    req_orders_response: null,

    req_order_loading: false,
    req_order_error: null,
    req_order_response: null,

    req_order_update_loading: false,
    req_order_update_error: null,
    req_order_update_response: null,

    req_order_delete_loading: false,
    req_order_delete_error: null,
    req_order_delete_response: null,

    add_product_error: null,
    //add_image_error: null,
    image_product_edited: false,

    req_order_init_loading: false,
    req_order_init_error: null,
    req_order_init_response: null,

    journals: [],
    req_journals_loading: false,
    req_journals_error: null,

    pdfStream: null,

    //PDF
    isDownloadingCustomerPDF: false,
    isPreviewingCustomerPDF: false,
    isDownloadingInternalPDF: false,
    isPreviewingInternalPDF: false,
}

const orderSlice = createSlice({
    name: 'order',
    initialState,
    reducers: {
        //to delete once order api fully ported to v2
        Request_Orders(state) {
            state.orders = [];
            state.req_orders_loading = true;
            state.req_orders_error = false;
            state.req_orders_response = false;
        },
        //to delete once order api fully ported to v2
        Request_Orders_Response(state, action) {
            state.req_orders_loading = false;
            state.req_orders_error = action.payload?.err;
            state.req_orders_response = action.payload?.data;

            state.orders = action.payload?.data;
        },
        Request_Orders_V2(state) {
            state.req_orders_v2_loading = true;
            state.req_orders_v2_error = false;
            state.req_orders_v2_response = false;


        },
        Request_Orders_Response_V2(state, action) {
            state.req_orders_v2_loading = false;
            state.req_orders_v2_error = action.payload?.err;
            state.req_orders_v2_response = action.payload?.data;
            
            state.totalOrderCount = action.payload?.data?.total;
            
            if(!action.payload?.err){
                if(action.payload.hasMoreOrders){
                    const newOrders = action.payload.data.orders;
                    state.ordersV2 = !!newOrders ? [...state.ordersV2, ...newOrders] : state.ordersV2;
                    state.currentRequestPage += 1;
                }else{
                    state.ordersV2 = action.payload?.data?.orders;
                }

                if(action.payload.forceResetPage){
                    state.currentRequestPage = 1;
                }
            }
            
        },
        Request_Orders_Response_V2_Append_Orders(state, action){
            state.req_orders_v2_loading = false;
            state.req_orders_v2_response = action.payload?.data;

            if(!!action.payload.err){
                state.req_orders_v2_error = action.payload.err;
            }else{
                const newOrders = action.payload.data.orders;
                state.ordersV2 = !!newOrders ? [...state.ordersV2, ...newOrders] : state.ordersV2;
            }
        },
        Request_Additional_Orders_Response(state, action) {
            state.req_orders_loading = false;
            state.req_orders_error = action.payload?.err;
            state.req_orders_response = action.payload?.data;

            state.orders = !!action.payload.data ? [...state.orders, ...action.payload.data] : state.orders;
        },
        Request_Order(state) {
            state.order = null;
            state.req_order_loading = true;
            state.req_order_error = null;
            state.req_order_response = null;

            // state.order.products = [];
            // state.add_product_error = null;
            // state.image_product_edited = false;
        },
        Request_Order_Response(state, action) {
            state.order = action.payload?.data;
            state.req_order_loading = false;
            state.req_order_error = action.payload?.err;
            state.req_order_response = action.payload?.data;
        },
        Request_Journals(state) {
            state.req_journals_loading = true
            state.req_journals_error = null
        },
        Request_Journals_Response(state, action) {
            state.req_journals_loading = false
            state.req_journals_error = action.payload?.err;
            state.journals = !!action.payload.data ? action.payload.data : state.journals;
        },
        Request_Update_Order(state) {
            state.req_order_update_loading = true
            state.req_order_update_error = null;
            state.req_order_update_response = null;
        },
        // Request_Update_Order_Success(state, action) {
        //     state.order = action.payload.data
        //     state.req_order_update_loading = false
        //     state.req_order_update_error = null
        //     state.req_order_update_response = action.payload.data;
        // },
        Request_Update_Order_Response(state, action) {
            state.order = action.payload.data ?? state.order
            state.req_order_update_loading = false
            state.req_order_update_error = action.payload.err;
            state.req_order_update_response = action.payload.data;

            if (action.payload.data) {
                const newOrder = action.payload.data;

                newOrder.products = newOrder.products.map(prod => {
                    return {
                        ...prod,
                        uploaded: true
                    }
                })

                state.orders = state.orders.map(order => {
                    return newOrder._id === order._id ? newOrder : order
                })
            }
        },
        Request_Delete_Order(state) {
            state.req_order_delete_loading = true
            state.req_order_delete_error = null;
            state.req_order_delete_response = null;
        },
        Request_Delete_Order_Response(state, action) {
            state.req_order_delete_loading = false
            state.req_order_delete_error = action.payload.err;
            state.req_order_delete_response = action.payload.data;

            if (action.payload.data) {
                state.orders = state.orders.filter(order => order._id !== action.payload.data._id)
            }
        },
        //RESET
        Reset_Update_Order_State(state) {
            state.image_product_edited = false;

            state.req_order_update_error = null;
            state.req_order_delete_error = null;
            state.add_product_error = null;
            //state.add_image_error = null;
            state.req_order_init_error = null;

            state.req_order_update_response = null;
            state.req_order_delete_response = null;
            state.req_order_init_response = null;
        },
        Set_Product(state, action) {
            state.order.products = action.payload;
            state.image_product_edited = true;
        },
        Add_Product(state, action) {
            state.order.products.push(action.payload.product);
            state.add_product_error = action.payload.err;
            state.image_product_edited = true;
        },
        Delete_Product(state, action) {
            state.add_product_error = action.payload.err;
            state.order.products = state.order.products.filter(p => p.id !== action.payload.productId);
            state.image_product_edited = true;
        },
        Add_Image(state, action) {
            //state.add_image_error = action.payload.err

            const product = state.order.products.find(prod => prod.id === action.payload.productId);
            //product.images.push(action.payload.img);
            
            product.cld_images.push(action.payload.cldimage);

            state.order.revision += 1;

            //No longer need to save since uploading image is saved immediately
            //state.image_product_edited = true;
        },
        Delete_Image(state, action) {
            const product = state.order.products.find(prod => prod.id === action.payload.productId);
            //product.images = product.images.filter(img => img.id !== action.payload.imageid);
            //state.add_image_error = action.payload.err

            product.cld_images = product.cld_images.filter(cld => cld.asset_id !== action.payload.asset_id);
            //No longer need to save since uploading image is saved immediately
            //state.image_product_edited = true;

            state.order.revision += 1;
        },
        Request_Init_Order(state) {
            state.req_order_init_loading = true
            state.req_order_init_error = null;
            state.req_order_init_response = null;
        },
        Request_Init_Order_Response(state, action) {
            state.req_order_init_loading = false
            state.req_order_init_error = action.payload.err;
            state.req_order_init_response = action.payload.data;
        },
        Request_Customer_Order_Form_Preview_Response(state, action) {
            state.pdfStream = action.payload
        },
        Request_Preview_PDF(state, action){
            if(action.payload.type === 'customer'){
                state.isPreviewingCustomerPDF = true;
            }else if(action.payload.type === 'internal'){
                state.isPreviewingInternalPDF = true
            }
        },
        Request_Preview_PDF_Response(state, action){
            if(action.payload.type === 'customer'){
                state.isPreviewingCustomerPDF = false;
            }else if(action.payload.type === 'internal'){
                state.isPreviewingInternalPDF = false
            }
        },
        Request_Download_PDF(state, action){
            if(action.payload.type === 'customer'){
                state.isDownloadingCustomerPDF = true;
            }else if(action.payload.type === 'internal'){
                state.isDownloadingInternalPDF = true;
            }
        },
        Request_Download_PDF_Response(state, action){
            if(action.payload.type === 'customer'){
                state.isDownloadingCustomerPDF = false;
            }else if(action.payload.type === 'internal'){
                state.isDownloadingInternalPDF = false;
            }
        },
        Update_Request_Page(state, action){
            state.currentRequestPage = action.payload?.data;
        },
        Update_Orders_Filter_Query(state, action){
            if(!!action.payload.data && Object.keys(action.payload.data).length > 0){
                if(!!state.filteredOrdersQuery){
                    state.filteredOrdersQuery = {
                        ...state.filteredOrdersQuery,
                        ...action.payload.data
                    }
                }else{
                    state.filteredOrdersQuery = {
                        ...action.payload.data
                    }
                }
            }else{
                state.filteredOrdersQuery = null;
            }
        },
    }
})

export default orderSlice.reducer;
export const orderActions = orderSlice.actions;