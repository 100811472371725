import React from 'react';

import styles from './Subheader.module.css';

import icon from '../../../assets/icon/users-icon.png';

const Subheader = ({ title }) => {
    return (
        <>
            <div className={styles.wrapper}>
                <img src={icon} alt="icon" className={styles.img} />
                <span>{title}</span>
            </div>
            <div className={styles.separator}></div>
        </>
    )
}

export default Subheader