import React from 'react'

import styles from './Base.module.css';

import Header from './Header';
import Footer from '../../../Layout/Footer';
import Navbar from '../../../Layout/Navbar';

import { Outlet } from "react-router-dom";

const Base = () => {
    return (
        <div className={styles.main}>
            <Header />
            <Navbar />
            <div className={styles['main-content']}>
                <Outlet />
            </div>
            <Footer />
        </div>
    )
}

export default Base