
import React, { 
    //useState, 
    useEffect } from 'react';
//import { useParams } from 'react-router-dom';
//import { OrderApi } from '../../Api';

// import { Worker, Viewer } from '@react-pdf-viewer/core';

// import axios from 'axios';

import styles from './PDFBlock.module.css';

//import Loading from '../../Layout/Loading';

const PDFBlock = () => {
    // const [pdf, setPdf] = useState(null);
    // const [loading, setLoading] = useState(true)

    // const { orderno } = useParams();

    useEffect(() => {

        // axios(`${OrderApi.ordersUrl}/${orderno}/pdf?mode=preview`,{
        //     method:'GET',
        //     responseType:'blob',
        //     headers:{
        //         'Content-Type': 'application/pdf',
        //         'Authorization': `Bearer ${localStorage.getItem('token')}`,
        //     }
        // }).then(res => {
        //     console.log(res)
        //     const file = new Blob([res.data],{type: 'application/pdf'})
        //     const fileUrl = URL.createObjectURL(file);
            
        //     setLoading(false);
        //     setPdf(fileUrl)
        // })

        //eslint-disable-next-line
    },[])

    return (
        <div className={styles.main}>

            {/* { loading && <Loading style={{width:'80%'}} loadingText='Loading preview'/> } */}

            {/* {
                pdf &&
                <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.3.122/build/pdf.worker.min.js">
                    <Viewer fileUrl={pdf} />
                </Worker>
            } */}
        </div>
    )
}

export default PDFBlock