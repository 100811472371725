import axios from "axios";
import store from "./store";
import { getAuthUserToken, logout } from "./store/auth-actions";

// eslint-disable-next-line
const devURL = 'http://localhost:8888/'
// eslint-disable-next-line
const prodURL = 'https://api.order.trea.mavezaj.com/'

const baseUrl = prodURL;

const createAxios = (baseURL) => {
    const newInstance = axios.create({ 
        baseURL,
        headers: {'Content-Type': 'application/json'}
    });

    newInstance.interceptors.request.use(function (config) {

        const token = getAuthUserToken();

        if(token){
            config.headers["Authorization"] = `Bearer ${token}`;
        }else{
            throw new axios.Cancel('TOKEN_EXPIRED');
        }
        
        return config;
    });

    newInstance.interceptors.response.use(
        response => response,
        (error) => {
            if (error && (error.response?.status === 401 || error.message === 'TOKEN_EXPIRED')) {
                store.dispatch(logout())
                window.location.href = '/login';
            }
            return Promise.reject(error);
        }
    );

    return newInstance;
}

export const orderApiV2 = createAxios(`${baseUrl}api/v1/orders`);
export const orderApiV2_v2 = createAxios(`${baseUrl}api/v2/orders`);
export const userApiV2 = createAxios(`${baseUrl}api/v1/users`);
export const xeroApi = createAxios(`${baseUrl}api/v1/xero`);
export const JournalApiV2 = createAxios(`${baseUrl}api/v1/journals`);
export const OptionApiV2 = createAxios(`${baseUrl}api/v1/options`);

export const AuthApi = {
    loginUrl: `${baseUrl}api/v1/auth/login`,
    logoutUrl: `${baseUrl}api/v1/auth/logout`
}