import React, { useState, useEffect } from 'react';

import styles from './XeroSection.module.css'

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import { useDispatch, useSelector } from 'react-redux';
import { xeroActions } from '../../../store/xero-slice';

import Loading from '../../../Layout/Loading';
import { getContact } from '../../../store/xero-actions';

/**
 * Phone number format
 * If PhoneCountryCode is 65 or null, just show PhoneNumber will do
 * If PhoneCountryCode is not 65, it should be external. Format below.
 * +[PhoneCountryCode] [PhoneAreaCode] [PhoneNumber]
 * 
 */
const toPhoneString = (phone) => {
    if ((phone.PhoneCountryCode && phone.PhoneCountryCode !== '65') ||
        (phone.PhoneAreaCode && phone.PhoneAreaCode !== '')) {
        var phonenumber = '';

        if (phone.PhoneCountryCode && phone.PhoneCountryCode !== '') {
            phonenumber = `+${phone.PhoneCountryCode}`
        }

        if (phone.PhoneAreaCode && phone.PhoneAreaCode !== '') {
            phonenumber += ` ${phone.PhoneAreaCode}`
        }

        if (phone.PhoneNumber && phone.PhoneNumber !== '') {
            phonenumber += ` ${phone.PhoneNumber}`
        }

        return phonenumber.trim();

    } else {
        return phone?.PhoneNumber ?? ''
    }
}

const toAddrString = (addr) => {
    /**
     * "AddressType": "STREET",
        "AddressLine1": "820 Thomson Rd",
        "AddressLine2": "Sinapore 574623",
        "AddressLine3"
        "AddressLine4": ""

        There are more below:
        "City", "Region", "PostalCode", "Country", "AttentionTo"

        From observation, TREA does not use the above info, so can skip for now.
     */

    var address = '';

    if (addr.AddressLine1 && addr.AddressLine1 !== '') {
        address = addr.AddressLine1;
    }

    if (addr.AddressLine2 && addr.AddressLine2 !== '') {
        address += ` ${addr.AddressLine2}`
    }

    if (addr.AddressLine3 && addr.AddressLine3 !== '') {
        address += ` ${addr.AddressLine3}`
    }

    if (addr.AddressLine4 && addr.AddressLine4 !== '') {
        address += ` ${addr.AddressLine4}`
    }

    return address.trim();
}

//Return name and email. Null if name and email empty.
const toContactPerson = (ctcPerson) => {
    const name = ((ctcPerson?.FirstName ?? '') + ' ' + (ctcPerson?.LastName ?? '')).trim();
    const email = ctcPerson?.EmailAddress ?? '';

    /**
     * Email can be empty which observed. But name is a must.
     */
    return name === '' && email === '' ?
        null :
        { name: name === '' ? 'NA' : name, email }
}

const toPriContactPerson = (FirstName, LastName, EmailAddress) => {
    return toContactPerson({ FirstName, LastName, EmailAddress })
}

const XeroSection = ({ onXeroItemSelected }) => {

    const dispatch = useDispatch();

    const [searchTerm, setSearchTerm] = useState('');

    const contacts = useSelector(state => state.xero.contacts);
    const isLoadingContacts = useSelector(state => state.xero.req_contacts_loading);
    const reqContactsError = useSelector(state => state.xero.req_contacts_error);

    const contactDetail = useSelector(state => state.xero.contact);
    const isLoadingContactDetail = useSelector(state => state.xero.req_contact_loading);

    const isLoading = isLoadingContacts || isLoadingContactDetail;

    const selectedContact = useSelector(state => state.xero.selectedContact);

    //Info state
    const [ctcPersons, setCtcPersons] = useState([])
    const [selectedCtcPerson, setSelectedCtcPerson] = useState(null);

    const [billingAddresses, setBillingAddresses] = useState([]);
    const [selectedBillingAddr, setSelectedBillingAddr] = useState(null);

    const [deliverySameAsBilling, setDeliverySameAsBilling] = useState(true);
    const [deliveryAddresses, setDeliveryAddresses] = useState([]);
    const [selectedDeliveryAddr, setSelectedDeliveryAddr] = useState(null);

    const [phones, setPhones] = useState([]);
    const [selectedPhone, setSelectedPhone] = useState(null);

    const [quotesInv, setQuotesInvs] = useState([]);

    const [selectedInv, setSelectedInv] = useState('');

    useEffect(() => {
        if (!selectedContact) {
            return;
        }

        setSelectedCtcPerson(null);
        setSelectedBillingAddr(null);
        setSelectedDeliveryAddr(null);
        setSelectedPhone(null);
        setSelectedInv('');
        setQuotesInvs([]);

        const allCtcs = [
            toPriContactPerson(selectedContact.FirstName, selectedContact.LastName, selectedContact.EmailAddress),
            //...selectedContact.ContactPersons.map(c => toContactPerson(c))
        ].filter(c => !!c)

        setCtcPersons(allCtcs)

        if (allCtcs.length > 0) {
            setSelectedCtcPerson(allCtcs[0])
        }

        const allAddrs = selectedContact.Addresses.map(addr => toAddrString(addr)).filter(addr => addr !== '');
        const allBillingAddrs = [...allAddrs];
        const allDeliveryAddrs = [...allAddrs];

        setBillingAddresses(allBillingAddrs);
        setDeliveryAddresses(allDeliveryAddrs)

        if (allBillingAddrs.length > 0) {
            setSelectedBillingAddr(allBillingAddrs[0])
            //Since default checked value for delivery is same as billing, set value tgt.
            setSelectedDeliveryAddr(allBillingAddrs[0])
        }

        const tempPhones = selectedContact.Phones.map(p => toPhoneString(p)).filter(p => p !== '');

        setPhones(tempPhones);

        if (tempPhones.length > 0) {
            setSelectedPhone(tempPhones[0])
        }

        if (!contactDetail) {
            dispatch(getContact(selectedContact.ContactID))
        }

        //eslint-disable-next-line
    }, [selectedContact])

    useEffect(() => {
        if (selectedContact && contactDetail) {
            setCtcPersons([
                ...ctcPersons,
                ...contactDetail.contact.ContactPersons.map(c => toContactPerson(c)).filter(c => !!c),
            ])
            
            const combinedQuotesInv = [...contactDetail.invoices, ...contactDetail.quotes]

            const normalised = combinedQuotesInv
                .filter(qi => { return (!!qi.QuoteNumber || (!!qi.Type && qi.Type !== 'ACCPAY')) })
                .map(qi => {

                    const type = !!qi.InvoiceID ? 'Invoice' : 'Quote'
                    
                    return {
                        id: type === 'Invoice' ? qi.InvoiceID : '',
                        type,
                        name: type === 'Invoice' ? qi.InvoiceNumber : qi.QuoteNumber,
                        //duedate: parseDueDate(qi.DueDateString),
                        duedate: qi.DueDateString ? new Date(qi.DueDateString) : null,
                        LineItems: qi.LineItems,
                        amountDue: type === 'Invoice' ? qi.AmountDue : -1,
                        amountPaid: type === 'Invoice' ? qi.AmountPaid : -1,
                        
                    }
                })

            setQuotesInvs(normalised);
        }
        //eslint-disable-next-line
    }, [contactDetail])

    const onContactSelected = (e, val) => {
        dispatch(xeroActions.Select_Contact(val));
    }

    const handleInvSelection = (e) => {
        setSelectedInv(e.target.value);
    }

    const onPopulateFormClicked = () => {
        
        const quoteInv = quotesInv.find(qi => qi.name === selectedInv);

        var paymentStatus = null;

        if(quoteInv && quoteInv.type === 'Invoice'){
            if(quoteInv.amountDue === 0){
                paymentStatus = 'Paid';
            }else if(quoteInv.amountPaid === 0){
                paymentStatus = 'Awaiting Payment';
            }else{
                paymentStatus = 'Partial Payment';
            }
        }else{
            paymentStatus = 'To Invoice'
        }
        
        onXeroItemSelected({
            companyName: selectedContact.Name,
            contactInfo: selectedCtcPerson,
            billingAddr: selectedBillingAddr,
            deliveryAddr: deliverySameAsBilling ? selectedBillingAddr : selectedDeliveryAddr,
            contactPhone: selectedPhone,
            quoteInv,
            xeroInvoiceId: quoteInv?.id ?? '',
            paymentStatus
        });
    }

    return (
        <div className={styles['xero-container']}>
            <header className={styles.header}>Xero Plugin</header>
            <div className={styles['section-container']}>
                <label className={styles['section-label']}>Search Contact</label>
                <Autocomplete
                    id="contact-field"
                    loading={isLoadingContacts}
                    loadingText="Loading Contacts"
                    getOptionLabel={(opt) => opt.Name}
                    options={contacts}
                    clearIcon={null}
                    disabled={isLoading}
                    onChange={onContactSelected}
                    isOptionEqualToValue={(option) => option.Name === selectedContact?.Name}
                    noOptionsText={searchTerm === '' ? 'Start typing to search' : 'No options'}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            size="small"
                            InputProps={{
                                ...params.InputProps,
                                type: 'search',
                            }}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                    )}
                />
                {isLoadingContactDetail && <Loading loadingText="" style={{ width: '100%' }} />}
                { reqContactsError && <div className={styles['error-text']}>{reqContactsError}</div> }
            </div>

            {
                selectedContact && <React.Fragment>
                    <div className={styles['section-container']}>
                        <label className={styles['section-label']}>Customer Info</label>
                        <div className={styles['section-body']}>
                            <div className={styles['field-info-div']}>
                                <label>Name</label>
                                <span>{selectedContact.Name}</span>
                            </div>

                            {
                                ctcPersons.length > 0 &&
                                <div className={styles['field-info-div']}>
                                    <label style={{ marginBottom: 4 }}>Contact(s)</label>
                                    {
                                        ctcPersons.map((ctc, i) => {
                                            return <div
                                                key={`ctc.${i}`}
                                                className={styles['option-selection-wrapper']}
                                                onClick={() => setSelectedCtcPerson(ctc)}
                                                style={{
                                                    backgroundColor: ctc.name === selectedCtcPerson.name &&
                                                        ctc.email === selectedCtcPerson.email ?
                                                        '#E2F0D9' : null
                                                }}>
                                                <span>{ctc.name}</span>
                                                <span>{ctc.email}</span>
                                            </div>
                                        })
                                    }
                                </div>
                            }

                            {
                                //Billing Address Selection
                                billingAddresses.length > 0 &&
                                <div className={styles['field-info-div']}>
                                    <label>Billing Address</label>
                                    {
                                        billingAddresses.map((addr, i) => {
                                            return <div
                                                key={`billingaddr.${i}`}
                                                className={styles['option-selection-wrapper']}
                                                onClick={() => setSelectedBillingAddr(addr)}
                                                style={{
                                                    backgroundColor: addr === selectedBillingAddr ?
                                                        '#E2F0D9' : null
                                                }}>
                                                <span>{addr}</span>
                                            </div>
                                        })
                                    }
                                </div>
                            }

                            {
                                // Delivery Address Selection
                                deliveryAddresses.length > 0 &&
                                <div className={styles['field-info-div']}>
                                    <label>Delivery Address</label>
                                    <FormControlLabel
                                        sx={{ height: '30px' }}
                                        control={<Checkbox size="small" name='delivery-cb' />}
                                        onChange={(e) => setDeliverySameAsBilling(e.target.checked)}
                                        checked={deliverySameAsBilling}
                                        label={<span style={{ fontSize: '0.8rem' }}>Same as billing</span>} />

                                    {
                                        !deliverySameAsBilling &&
                                        deliveryAddresses.map((addr, i) => {
                                            return <div
                                                key={`deliveryaddr.${i}`}
                                                className={styles['option-selection-wrapper']}
                                                onClick={() => setSelectedDeliveryAddr(addr)}
                                                style={{
                                                    backgroundColor: addr === selectedDeliveryAddr ?
                                                        '#E2F0D9' : null
                                                }}>
                                                <span>{addr}</span>
                                            </div>
                                        })
                                    }
                                </div>
                            }

                            {/* Contact Selection */}
                            {
                                phones.length > 0 &&
                                <div className={styles['field-info-div']}>
                                    <label>Contact</label>
                                    <RadioGroup
                                        aria-labelledby="inv-buttons-group"
                                        name="phone-buttons-group"
                                        value={selectedPhone}
                                        onChange={(e) => setSelectedPhone(e.target.value)}>
                                        {
                                            phones.map((phone, i) => {
                                                return <FormControlLabel
                                                    key={i}
                                                    value={phone}
                                                    control={<Radio size="small" sx={{ height: 20 }} />}
                                                    label={<span style={{ fontSize: '0.8rem' }}>{phone}</span>} />
                                            })
                                        }
                                    </RadioGroup>
                                </div>
                            }
                        </div>
                    </div>

                    <div className={styles['section-container']}>
                        <label className={styles['section-label']}>Invoices / Quotes</label>
                        <div className={styles['section-body']}>
                            <RadioGroup
                                aria-labelledby="inv-buttons-group"
                                name="inv-buttons-group"
                                value={selectedInv}
                                onChange={handleInvSelection}>
                                {
                                    quotesInv.map((inv, i) => {
                                        return <FormControlLabel
                                            key={`quoteinv-${i}`}
                                            value={inv.name}
                                            control={<Radio
                                                size="small"
                                                sx={{ height: 20 }} />}
                                            label={<span style={{ fontSize: '0.8rem' }}>{inv.name}</span>} />
                                    })
                                }
                            </RadioGroup>
                        </div>
                    </div>

                    <Button
                        variant="contained"
                        color="success"
                        disabled={isLoading}
                        onClick={onPopulateFormClicked}>
                        Populate Form
                    </Button>
                </React.Fragment>
            }
        </div>
    )
}

export default XeroSection