import React, { useState } from 'react';

import styles from './Header.module.css';
import logo from '../../../assets/logo/trea-logo.png';

//Material API
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import Logout from '@mui/icons-material/Logout';
import Avatar from '@mui/material/Avatar';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import { useNavigate } from "react-router-dom";

import { useSelector, useDispatch } from 'react-redux';

import { logout } from '../../../store/auth-actions';

const Header = () => {
    const navigate = useNavigate();

    const authUser = useSelector(state => state.auth.authUser);
    const dispatch = useDispatch();

    const [anchorEl, setAnchorEl] = useState(false);

    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(false);
    };

    const onClickMyAccount = () => {
        navigate('/profile');
    }

    const title = 'Order Form System'

    const logoutHandler = () => {
        dispatch(logout())
        navigate('/login');
    }

    return (
        <header className={styles.header}>
            <img src={logo} className={styles.img} alt="logo"></img>
            <span className={styles['app-title']}>{title}</span>
            <div>
                <div className={styles.userdiv}>
                    <span>{authUser?.displayname}</span>
                    <span>{authUser?.role}</span>
                </div>
            </div>
            <IconButton
                onClick={handleClick}
                aria-label="delete"
                size="large">
                <MoreVertIcon />
            </IconButton>

            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>

                <MenuItem onClick={onClickMyAccount}>
                    <Avatar /> My Account
                </MenuItem>
                <Divider />
                <MenuItem onClick={logoutHandler}>
                    <ListItemIcon><Logout /></ListItemIcon>
                    Logout
                </MenuItem>
            </Menu>
        </header>
    )
}

export default Header