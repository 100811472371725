import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    authUser: null,

    req_loading: false,
    req_error: null,
    req_response: null,

    //temp field, not needed once auth is properly implemented.
    isAuthed: false,

    xeroAuthCode: null
}

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers:{
        login(state) {
            state.req_loading = true;
        },//not needed
        onLoginStart(state) {
            state.req_loading = true;
        },
        logout(state) {
            state.authUser = null;
        },
        onLoginFailed(state, action){
            state.req_error = action.payload
            state.req_loading = false;
        },
        onLoginSuccess(state, action){
            state.req_loading = false;
            state.req_error = null;

            state.authUser = action.payload
        },
        Update(state, action){
            state.authUser = action.payload
        },
        SetAuthCode(state, action){
            state.xeroAuthCode = action.payload
        }
    }
})

export default authSlice.reducer;
export const authActions = authSlice.actions;