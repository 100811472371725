import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    contacts: [],
    req_contacts_loading: false,
    req_contacts_error: null,

    contact: null,
    req_contact_loading: false,
    req_contact_error: null,

    selectedContact: null,
}

const xeroSlice = createSlice({
    name: 'xero',
    initialState,
    reducers: {
        Request_Contacts(state) {
            state.req_contacts_loading = true;
            state.req_contacts_error = null;
            
            state.selectedContact = null;
            state.contact = null;

        },
        Request_Contacts_Response(state, action){
            state.req_contacts_loading = false;
            state.contacts = action.payload;
        },
        Request_Contacts_Error(state, action){
            state.req_contacts_loading = false;
            state.req_contacts_error = action.payload;
        },
        Select_Contact(state, action){
            state.selectedContact = action.payload;
            state.contact = null;
        },
        Request_Contact(state) {
            state.contact = null;
            state.req_contact_loading = true;
            state.req_contact_error = null;
        },
        Request_Contact_Response(state, action){
            state.req_contact_loading = false;
            state.contact = action.payload;
        },
        Request_Contact_Error(state, action){
            state.req_contact_loading = false;
            state.req_contact_error = action.payload;
        },
    }
})

export default xeroSlice.reducer;
export const xeroActions = xeroSlice.actions;