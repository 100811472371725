
import styles from './AddUserModal.module.css';
import ReactDOM from 'react-dom';

import Subheader from '../../Pages/Private/Base/Subheader';
import TextField from '@mui/material/TextField';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';

import UserInput from '../user-input-hook';

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import NotificationBlock from '../Alert/NotificationBlock';
import { addUser } from '../../store/user-actions';

const inputStyle = {
    "& .MuiInputBase-root": {
        height: 30,
        padding: 0
    }
}

const Backdrop = (props) => {
    return <div className={styles.backdrop} onClick={props.onClick}></div>
}

const ModalOverlay = props => {

    const dispatch = useDispatch();

    const isAddingUser = useSelector(state => state.user.req_add_loading);
    const addResponse = useSelector(state => state.user.req_add_response);
    const addError = useSelector(state => state.user.req_add_error);

    //Form state
    const {
        value: enteredFirstName,
        isValid: enteredFirstNameIsValid,
        hasError: firstNameInputHasError,
        valueChangeHandler: firstNameChangedHandler,
        inputBlurHandler: firstNameBlurHandler } = UserInput(value => value.trim() !== '');

    const {
        value: enteredLastName,
        valueChangeHandler: lastNameChangedHandler } = UserInput();

    const {
        value: enteredDisplayName,
        isValid: enteredDisplayNameIsValid,
        hasError: displayNameInputHasError,
        valueChangeHandler: displayNameChangedHandler,
        inputBlurHandler: displayNameBlurHandler } = UserInput(value => value.trim() !== '');

    const {
        value: enteredEmail,
        isValid: enteredEmailIsValid,
        hasError: emailInputHasError,
        valueChangeHandler: emailChangedHandler,
        inputBlurHandler: emailBlurHandler } = UserInput(value => value.trim() !== '');

    const {
        value: enteredContact,
        valueChangeHandler: contactChangedHandler } = UserInput();

    const [selectedRole, setSelectedRole] = useState('Standard')

    const {
        value: enteredPassword,
        isValid: enteredPasswordIsValid,
        hasError: passwordInputHasError,
        valueChangeHandler: passwordChangedHandler,
        inputBlurHandler: passwordBlurHandler } = UserInput(value => value !== '' && value.length >= 7);

    const {
        value: enteredCfmPassword,
        isValid: enteredCfmPasswordIsValid,
        hasError: cfmPasswordInputHasError,
        valueChangeHandler: cfmPasswordChangedHandler,
        inputBlurHandler: cfmPasswordBlurHandler } = UserInput(value => value !== '' && value === enteredPassword);

    const passwordErrorText = () => {
        if (!passwordInputHasError) {
            return null;
        }

        return enteredPassword === '' ?
            'Password cannot be empty' :
            'password must have more than 6 chars'
    }

    const cfmPasswordErrorText = () => {
        if (!cfmPasswordInputHasError) {
            return null;
        }

        return enteredCfmPassword === '' ?
            'Password cannot be empty' :
            'password does not match'
    }

    let formIsValid = enteredFirstNameIsValid &&
        enteredDisplayNameIsValid &&
        enteredEmailIsValid &&
        enteredPasswordIsValid &&
        enteredCfmPasswordIsValid

    useEffect(() => {
        if(addResponse){
            props.onSubmit();
        }
        //eslint-disable-next-line
    },[addResponse])

    const handleRoleSelection = (e) => {
        setSelectedRole(e.target.value)
    }

    const onAddUser = (e) => {
        e.preventDefault();

        const firstname = enteredFirstName.length === 1 ? 
                            enteredFirstName.toUpperCase() :
                            enteredFirstName.substring(0,1).toUpperCase() + enteredFirstName.substring(1).toLowerCase()

        let lastname = enteredLastName;

        if(lastname.length > 0){
            lastname = lastname.length === 1 ? 
                            lastname.toUpperCase() :
                            lastname.substring(0,1).toUpperCase() + lastname.substring(1).toLowerCase()
        }

        const user = {
            email: enteredEmail,
            password: enteredPassword,
            firstname,
            lastname,
            displayname: enteredDisplayName,
            role: selectedRole,
            contact: enteredContact
        }

        dispatch(addUser(user));
    }

    return (
        <div className={styles.modal}>
            <Subheader title="Add User" />
            <form className={styles['form-wrapper']} onSubmit={onAddUser}>

                { addError && <NotificationBlock errorText={addError}/> }

                <div className={styles['input-group']}>
                    First Name
                    <TextField
                        sx={inputStyle}
                        error={firstNameInputHasError}
                        id="firstname"
                        variant="outlined"
                        size='small'
                        value={enteredFirstName}
                        onChange={firstNameChangedHandler}
                        onBlur={firstNameBlurHandler}
                        helperText={firstNameInputHasError ? 'Cannot be empty' : null} />
                </div>
                <div className={styles['input-group']}>
                    Last Name
                    <TextField
                        sx={inputStyle}
                        id="lastname"
                        variant="outlined"
                        size='small'
                        value={enteredLastName}
                        onChange={lastNameChangedHandler} />
                </div>
                <div className={styles['input-group']}>
                    Display Name
                    <TextField
                        sx={inputStyle}
                        error={displayNameInputHasError}
                        id="displayname"
                        variant="outlined"
                        size='small'
                        value={enteredDisplayName}
                        onChange={displayNameChangedHandler}
                        onBlur={displayNameBlurHandler}
                        helperText={displayNameInputHasError ? 'Cannot be empty' : null} />
                </div>
                <div className={styles['input-group']}>
                    Email
                    <TextField
                        sx={inputStyle}
                        error={emailInputHasError}
                        id="email"
                        variant="outlined"
                        size='small'
                        type="email"
                        value={enteredEmail}
                        onChange={emailChangedHandler}
                        onBlur={emailBlurHandler}
                        helperText={emailInputHasError ? 'Cannot be empty' : null} />
                </div>
                <div className={styles['input-group']}>
                    Contact
                    <TextField
                        sx={inputStyle}
                        id="contact"
                        variant="outlined"
                        size='small'
                        value={enteredContact}
                        onChange={contactChangedHandler} />
                </div>
                <div className={styles['input-group']}>
                    User Type
                    <RadioGroup
                        aria-labelledby="role-buttons-group"
                        name="role-buttons-group"
                        value={selectedRole}
                        onChange={handleRoleSelection}
                    >
                        <FormControlLabel
                            value="Manager"
                            control={<Radio size="small" />}
                            label={<span>Manager</span>} />

                        <FormControlLabel
                            value="Standard"
                            control={<Radio size="small" />}
                            label={<span>Standard</span>} />
                    </RadioGroup>
                </div>
                <div className={styles['input-group']}>
                    Password
                    <TextField
                        sx={inputStyle}
                        error={passwordInputHasError}
                        id="password"
                        variant="outlined"
                        size='small'
                        type="password"
                        value={enteredPassword}
                        onChange={passwordChangedHandler}
                        onBlur={passwordBlurHandler}
                        helperText={passwordErrorText()} />
                </div>
                <div className={styles['input-group']}>
                    Confirm Password
                    <TextField
                        sx={inputStyle}
                        error={cfmPasswordInputHasError}
                        id="cfmpassword"
                        variant="outlined"
                        size='small'
                        type="password"
                        value={enteredCfmPassword}
                        onChange={cfmPasswordChangedHandler}
                        onBlur={cfmPasswordBlurHandler}
                        helperText={cfmPasswordErrorText()} />
                </div>
                <div className={styles.actions}>
                    <Button
                        variant="contained"
                        color="secondary"
                        sx={{ width: "50%", backgroundColor: 'gray' }}
                        onClick={props.onClose}>
                        Close
                    </Button>

                    <Button
                        variant="contained"
                        color="success"
                        sx={{ width: "50%" }}
                        disabled={!formIsValid || isAddingUser}
                        onClick={onAddUser}>Add</Button>
                </div>
            </form>
        </div>
    )
}

const RenderAddUserModal = (props) => {
    return (
        <>
            {
                ReactDOM.createPortal(
                    <Backdrop onClick={props.onClose} />,
                    document.getElementById('backdrop-root'))
            }
            {
                ReactDOM.createPortal(
                    <ModalOverlay onSubmit={props.onSubmit} onClose={props.onClose} />,
                    document.getElementById('overlay-root'))
            }
        </>
    )
}

export default RenderAddUserModal