import { orderApiV2, JournalApiV2, orderApiV2_v2 } from "../Api";
import { orderActions } from "./order-slice";

import uuid from "react-uuid";

import { OrderConstant } from '../Constants';

const supplierTemplate = {
    name: '',
    shipDate: null,
    shipMode: '',
    cost: 0,
    trackingRef: ''
}

const {
    MaxProductAllowed: productLimit,
    //MaxTotalImageSize: maxImgSize,
    //MaxImageUploadCountPerProduct: maxImgUploadCount,
} = OrderConstant

async function pdfDownload(copytype, orderid) {

    return orderApiV2.get(`/${orderid}/pdf?mode=download&type=${copytype}`)
        .then(res => {
            var binary = atob(res.data.data.replace(/\s/g, ''));
            var len = binary.length;
            var buffer = new ArrayBuffer(len);
            var view = new Uint8Array(buffer);
            for (var i = 0; i < len; i++) {
                view[i] = binary.charCodeAt(i);
            }

            const file = new Blob([view], { type: 'application/pdf' })
            const fileUrl = URL.createObjectURL(file);

            let alink = document.createElement('a');
            alink.href = fileUrl
            alink.download = res.data.filename
            alink.click()
        })
}

async function pdfPreview(copytype, orderid) {
    return orderApiV2.get(`/${orderid}/pdf?mode=preview&type=${copytype}`, { responseType: 'blob', })
        .then(res => {
            const file = new Blob([res.data], { type: 'application/pdf' })
            const fileUrl = URL.createObjectURL(file);
            window.open(fileUrl)
        })
}

export const getOrdersV2 = (page, searchFields, forceResetPage = false) => {
    return async (dispatch, getState) => {
        dispatch(orderActions.Request_Orders_V2());

        const currentPage = getState().order.currentRequestPage;
        const requestLimit = OrderConstant.MaxOrderRequestLimit;
        const fields = getState().order.filteredOrdersQuery;

        //console.log(`Getting version 2 orders.. requesting page ${page}, current page: ${currentPage}`)
        //console.log(fields)
        var query = `?page=${page}&limit=${requestLimit}`

        if (!!fields && Object.keys(fields).length > 0) {

            query += '&'

            query += Object.keys(fields).filter(key => !!fields[key]).map(key => {
                return `${key}=${fields[key]}`
            }).join('&')
        }

        try {
            const response = await orderApiV2_v2.get(query);
            // console.log(`Query: ${query}`)
            // console.log(response.data)
            
            dispatch(orderActions.Request_Orders_Response_V2({
                data: response.data.data,
                hasMoreOrders: page > currentPage,
                forceResetPage,
                err: response.data.error?.description ?? null 
            }))

        }catch(error){
            //Over here will not ever catch error coming from server side. Server API error also wrapped in 200 status code
            dispatch(orderActions.Request_Orders_Response_V2({
                data: null,
                err: error.message
            }))
        }
    }
}

// export const updateOrderV2Limit = (limit) => {
//     return async (dispatch) => {
//         dispatch(orderActions.Update_OrderTable_Limit({ data: limit }))
//     }
// }

export const updateOrderV2RequestPage = (page) => {
    return async (dispatch) => {
        dispatch(orderActions.Update_Request_Page({ data: page }))
    }
}

export const updateOrderV2Query = (fields) => {
    return async (dispatch) => {
        dispatch(orderActions.Update_Orders_Filter_Query({ data: fields }))
    }
}

export const getOrders = (page = 1, searchFields) => {
    return async (dispatch) => {
        dispatch(orderActions.Request_Orders());

        var query = ''

        if (!!searchFields && Object.keys(searchFields).length > 0) {

            query = '?'

            query += Object.keys(searchFields).map(key => {
                return `${key}=${searchFields[key]}`
            }).join('&')
        }

        try {
            const response = await orderApiV2.get(query);

            if (page > 1) {
                dispatch(orderActions.Request_Additional_Orders_Response({
                    data: response.data,
                    err: null
                }))
            } else {
                dispatch(orderActions.Request_Orders_Response({
                    data: response.data,
                    err: null
                }))
            }
        } catch (error) {
            const errText = error.response.data.message || error.message;

            if (page > 1) {
                dispatch(orderActions.Request_Additional_Orders_Response({
                    data: null,
                    err: errText
                }))
            } else {
                dispatch(orderActions.Request_Orders_Response({
                    data: null,
                    err: errText
                }))
            }
        }
    }
}

export const initOrder = (id) => {
    return async (dispatch) => {

        dispatch(orderActions.Request_Init_Order());

        orderApiV2.post('/init').then(response => {
            dispatch(orderActions.Request_Init_Order_Response({ data: response.data, err: null }))
        }).catch(err => {
            dispatch(orderActions.Request_Init_Order_Response({ data: null, err: err.response.data.message }))
        })
    }
}

export const previewCustomerPdf = (orderid) => {
    return async (dispatch) => {
        dispatch(orderActions.Request_Preview_PDF({type:'customer'}))
        await pdfPreview('customer', orderid);
        dispatch(orderActions.Request_Preview_PDF_Response({type:'customer'}))
    }
}

export const previewInternalPdf = (orderid) => {
    return async (dispatch) => {
        dispatch(orderActions.Request_Preview_PDF({type:'internal'}))
        await pdfPreview('internal', orderid, dispatch);
        dispatch(orderActions.Request_Preview_PDF_Response({type:'internal'}))
    }
}

export const downloadCustomerPdf = (orderid) => {
    return async (dispatch) => {
        dispatch(orderActions.Request_Download_PDF({type:'customer'}))
        await pdfDownload('customer', orderid);
        dispatch(orderActions.Request_Download_PDF_Response({type:'customer'}))
    }
}

export const downloadInternalPdf = (orderid) => {
    return async (dispatch) => {
        dispatch(orderActions.Request_Download_PDF({type:'internal'}))
        pdfDownload('internal', orderid);
        dispatch(orderActions.Request_Download_PDF_Response({type:'internal'}))
    }
}

export const getOrder = (id) => {
    return async (dispatch) => {
        dispatch(orderActions.Reset_Update_Order_State());
        dispatch(orderActions.Request_Order());

        orderApiV2.get(`/${id}`).then(response => {
            dispatch(orderActions.Request_Order_Response({ data: response.data, err: null }))
        }).catch(err => {
            dispatch(orderActions.Request_Order_Response({ data: null, err: err.response.data.message }))
        })
    }
}

export const updateOrder = (id, order) => {
    return async (dispatch) => {

        dispatch(orderActions.Request_Update_Order());

        orderApiV2.post(`/${id}`, { ...order }).then(response => {
            dispatch(orderActions.Request_Update_Order_Response({ data: response.data, err: null }))
        }).catch(err => {
            dispatch(orderActions.Request_Update_Order_Response({ data: null, err: err.response.data.message }))
        })
    }
}

export const deleteOrder = (id) => {
    return async (dispatch) => {

        dispatch(orderActions.Request_Delete_Order());

        orderApiV2.delete(`/${id}`).then(response => {
            dispatch(orderActions.Request_Delete_Order_Response({ data: response.data, err: null }))
        }).catch(err => {
            dispatch(orderActions.Request_Delete_Order_Response({ data: null, err: err.response.data.message }))
        })
    }
}

export const addProduct = () => {
    return (dispatch, getState) => {
        const order = getState().order.order;

        let err = null;
        let product = null;

        if (order.products.length >= productLimit) {
            err = `Max product limit hit. You can only add up to ${productLimit} products.`
        }

        product = {
            id: uuid(),
            name: '',
            qty: 0,
            unitCost: 0,
            deliveryFee: 0,
            packaging: 0,
            specification: '',
            printing: '',
            packagingInstruction: '',
            images: [],
            cld_images:[],
            productSupplier: { ...supplierTemplate },
            printMaterialSupplier: { ...supplierTemplate },
            printServiceSupplier: { ...supplierTemplate },
            totalAmt: 0,
            exportable: true,
            uploaded: false
        }

        dispatch(orderActions.Add_Product({ product, err }));
    }
}

export const SetProducts = (lineitems) => {
    return (dispatch) => {
        if (lineitems.length === 0) {
            dispatch(orderActions.Set_Product([]))
            return;
        }

        const products = lineitems.map(li => {
            const name = !!li.Item ? li.Item.Name : li.ItemCode ?? li.Description

            return {
                id: li.LineItemID,
                name,
                qty: li.Quantity ?? 0,
                unitCost: li.UnitAmount ?? 0,
                deliveryFee: 0,
                packaging: 0,
                specification: '',
                printing: '',
                packagingInstruction: '',
                images: [],
                cld_images:[],
                productSupplier: { ...supplierTemplate },
                printMaterialSupplier: { ...supplierTemplate },
                printServiceSupplier: { ...supplierTemplate },
                totalAmt: li.LineAmount ?? 0,
                exportable: true,
                uploaded: false
            }
        })

        dispatch(orderActions.Set_Product(products))
    }
}

export const deleteProduct = (productId) => {
    return (dispatch, getState) => {
        const order = getState().order.order;

        let err = null;

        if ((order.products.length - 1) >= productLimit) {
            err = `Max product limit hit. You can only add up to ${productLimit} products.`
        }

        dispatch(orderActions.Delete_Product({ productId, err }));
    }
}

export const onImageUpload = (productId, cldimage) => {
    return (dispatch) => {
        dispatch(orderActions.Add_Image({ productId, cldimage }));
    }
}

export const onImageDelete = (productId, cldimage_asset_id) => {
    return (dispatch) => {
        dispatch(orderActions.Delete_Image({ productId, asset_id: cldimage_asset_id}));
    }
}

//To delete once onImageUpload is implemented
// export const addImage = (productId, img) => {
//     return (dispatch, getState) => {
//         const order = getState().order.order;
//         const product = order.products.find(p => p.id === productId)

//         let totalImageSize = 0
//         const uploadcount = product.images.length

//         let err = null;

//         order.products.forEach(prod => {
//             prod.images.forEach(img => {
//                 totalImageSize += img.filesize;
//             })
//         });

//         if (uploadcount >= maxImgUploadCount) {
//             err = `Each product can only upload up to ${maxImgUploadCount} images.`
//         } else if (totalImageSize >= maxImgSize) {
//             err = `Maximum size limit hit of ${maxImgSize / 1024 / 1024} MB. Consider resizing your image.`
//         }

//         dispatch(orderActions.Add_Image({ img, productId, err }));
//     }
// }

// export const deleteImage = (productId, image) => {
//     return (dispatch, getState) => {
//         const imageid = image.id;
//         const order = getState().order.order;
//         const product = order.products.find(p => p.id === productId)

//         let totalImageSize = 0
//         const uploadcount = product.images.length - 1;

//         let err = null;

//         order.products.forEach(prod => {
//             prod.images.forEach(img => {
//                 totalImageSize += img.filesize;
//             })
//         });

//         totalImageSize -= image.filesize;

//         if (uploadcount >= maxImgUploadCount) {
//             err = `Each product can only upload up to ${maxImgUploadCount} images.`
//         } else if (totalImageSize >= maxImgSize) {
//             err = `Maximum size limit hit of ${maxImgSize / 1024 / 1024} MB. Consider resizing your image.`
//         }

//         dispatch(orderActions.Delete_Image({ imageid, productId, err }));
//     }
// }

export const getJournals = () => {
    return async (dispatch) => {

        dispatch(orderActions.Request_Journals());

        JournalApiV2.get()
            .then(response => {
                dispatch(orderActions.Request_Journals_Response({ data: response.data, err: null }))
            }).catch(err => {
                dispatch(orderActions.Request_Journals_Response({ data: null, err: err.response.data.message }))
            })
    }
}

export const resetOrderState = (type) => {
    return (dispatch) => {
        switch (type) {
            case 'create':
                dispatch(orderActions.Request_Init_Order_Response({ data: null, err: null }))
                return;
            case 'update':
                dispatch(orderActions.Request_Update_Order_Fail({ data: null, err: null }))
                return
            case 'delete':
                dispatch(orderActions.Request_Delete_Order_Response({ data: null, err: null }))
                return
            default:
                return;
        }
    }
}