import React, { forwardRef } from 'react';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Fade } from '@mui/material';

const Transition = forwardRef(function Transition(props, ref) {
  return <Fade direction="up" ref={ref} {...props} />;
});

const ConfirmationDialog = (props) => {

  const {
    open,
    onCancel,
    onAccept,
    title,
    content,
    cancelButtonText,
    acceptButtonText } = props;

  const cancelHandler = onCancel ?? (() => { })
  const acceptHandler = onAccept ?? (() => { })
  const _cancelButtonText = cancelButtonText ?? 'Cancel';
  const _acceptButtonText = acceptButtonText ?? 'Yes';

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={cancelHandler}
    >
      <DialogTitle sx={{
        fontSize:'1rem',
        padding:'8px 24px',
        backgroundColor:'#eee'
      }}>{title}</DialogTitle>
      <DialogContent dividers>
        <DialogContentText id="alert-dialog-slide-description" sx={{color:'black'}}>
          {content}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={cancelHandler}>{_cancelButtonText}</Button>
        <Button onClick={acceptHandler}>{_acceptButtonText}</Button>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmationDialog