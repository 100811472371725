import React from 'react';

import { ErrorOutline } from '@mui/icons-material';

const NotificationBlock = ({errorText}) => {
  return (
    <div style={{
        display:'flex',
        gap:'8px',
        border:'1px solid red',
        fontSize:'0.8rem',
        backgroundColor:'#EF9A9A',
        padding:'4px',
        alignItems:'center'
    }}>
        <ErrorOutline color="error"/>
        <span>{errorText}</span>
    </div>
  )
}

export default NotificationBlock