import React, { useEffect, useState } from 'react';
import styles from './FormOverview.module.css';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useSelector } from 'react-redux';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';

const inputStyle = {
    "& .MuiInputBase-root": {
        height: 30,
        padding: 0
    },
    "& .MuiOutlinedInput-root.MuiInputBase-sizeSmall": {
        padding: 0
    },
    width: '100%'
}

const FormOverview = ({ formData, handleInputChange, formHeader }) => {

    const options = useSelector(state => state.option.options);
    const optionLoading = useSelector(state => state.option.req_options_loading);
    const order = useSelector(state => state.order.order);
    const users = useSelector(state => state.user.users);

    //example of delayed field check
    let fieldState = false;

    //Options fields
    const [sampleDeliveryOptions, setSampleDeliveryOptions] = useState([]);
    const [massOrderOptions, setMassOrderOptions] = useState([]);
    const [paymentTermOptions, setPaymentTermOptions] = useState([]);
    const [paymentModeOptions, setPaymentModeOptions] = useState([]);

    const [salesRepOptions, setSalesRepOptions] = useState([]);
    const [salesrep, setSalesrep] = useState(null);

    useEffect(() => {
        if (!order.salesrepid) {
            return;
        }
        const salesrep = { _id: order.salesrepid, firstname: order.salesrep, lastname: '' }

        setSalesrep(salesrep)
        //eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (options) {
            options.forEach(opt => {
                switch (opt.name) {
                    case 'paymentMode':
                        setPaymentModeOptions(opt.value)
                        break;
                    case 'paymentTerm':
                        setPaymentTermOptions(opt.value)
                        break;
                    case 'massOrder':
                        setMassOrderOptions(opt.value)
                        break;
                    case 'sampleDelivery':
                        setSampleDeliveryOptions(opt.value)
                        break;
                    default:
                }
            })
        }
        //eslint-disable-next-line
    }, [options.length])

    //Will run for every field state update
    useEffect(() => {
        const identifier = setTimeout(() => {

        }, 1000)

        return () => { clearTimeout(identifier); }
    }, [fieldState]);

    useEffect(() => {
        if (users.length > 0) {
            const ids = users.map(user => user._id);

            /**
             * When autocomplete options value does not exist in options,
             * it will throw error. When the form user no longer in the company and
             * the account got deleted, without the code below, it will cause error.
             */
            if (!ids.includes(formData.salesrepid)) {
                const rep = {
                    _id: formData.salesrepid,
                    firstname: formData.salesrep,
                    lastname: ''
                };

                if (formData.salesrepid) {
                    setSalesRepOptions([rep, ...users]);
                } else {
                    setSalesRepOptions([...users]);
                }
            } else {
                setSalesRepOptions(users);
            }
        }
        //eslint-disable-next-line
    }, [users.length])

    const salesRepAutoCompleteHandler = (e, newVal) => {
        setSalesrep(newVal ?? null);
        handleInputChange({ target: { name: 'salesrepACField', value: newVal } })
    }

    const sampleDeliveryAutoCompleteHandler = (e, newVal) => {
        handleInputChange({ target: { name: 'sampleDelivery', value: newVal } })
    }

    const massOrderAutoCompleteHandler = (e, newVal) => {
        handleInputChange({ target: { name: 'massOrderDelivery', value: newVal } })
    }

    const paymentTermAutoCompleteHandler = (e, newVal) => {
        handleInputChange({ target: { name: 'paymentTerm', value: newVal } })
    }

    const paymentModeAutoCompleteHandler = (e, newVal) => {
        handleInputChange({ target: { name: 'paymentMode', value: newVal } })
    }

    const paymentDueDateHandler = (e) => {
        handleInputChange({ target: { name: 'paymentDueDate', value: e['$d'] } })
    }

    return (
        <div className={styles.container}>
            <div className={styles['sales-rep-wrapper']}>
                <span>SALES REP INFO</span>
                <div style={{ display: 'flex', gap: '4px' }}>
                    <Autocomplete
                        id="repname"
                        size='small'
                        options={salesRepOptions}
                        sx={inputStyle}
                        value={salesrep}
                        onChange={salesRepAutoCompleteHandler}
                        getOptionLabel={(option) => `${option?.firstname} ${option?.lastname}`.trim() ?? ''}
                        loading={optionLoading}
                        isOptionEqualToValue={(option, val) => {
                            return option._id === val._id
                        }}
                        renderInput={(params) => <TextField {...params} />
                        }
                    />
                    <TextField
                        id="repcontact"
                        name="salesrepContact"
                        variant="outlined"
                        size='small'
                        placeholder='Contact'
                        sx={inputStyle}
                        value={formData.salesrepContact}
                        onChange={handleInputChange}
                    />
                </div>
            </div>

            <div className={styles['po-wrapper']}>
                <div className={styles['txtfield-wrapper']}>
                    <span>PO. No.</span>
                    <TextField
                        id="pono"
                        variant="outlined"
                        size='small'
                        sx={inputStyle}
                        name='poNo'
                        value={formData.poNo}
                        onChange={handleInputChange}
                    />
                </div>
                <div className={styles['txtfield-wrapper']}>
                    <span>Quotation No.</span>
                    <TextField
                        id="quote"
                        variant="outlined"
                        size='small'
                        name='quoteNo'
                        sx={inputStyle}
                        value={formData.quoteNo}
                        onChange={handleInputChange}
                    />
                </div>
                <div className={styles['txtfield-wrapper']}>
                    <span>Invoice No.</span>
                    <TextField
                        id="invoice"
                        variant="outlined"
                        size='small'
                        name='invoiceNo'
                        sx={inputStyle}
                        disabled={formHeader.xeroInvoiceId !== ''}
                        value={formData.invoiceNo}
                        onChange={handleInputChange}
                    />
                </div>
            </div>

            <div className={styles['sample-wrapper']}>
                <div className={styles['txtfield-wrapper']}>
                    <span>SAMPLE DELIVERY (If applicable)</span>
                    {/* sampleDeliveryAutoCompleteHandler */}
                    <Autocomplete
                        id="sampledelivery"
                        size='small'
                        options={sampleDeliveryOptions}
                        sx={inputStyle}
                        freeSolo
                        value={formData.sampleDelivery}
                        onChange={sampleDeliveryAutoCompleteHandler}
                        loading={optionLoading}
                        renderInput={(params) =>
                            <TextField
                                {...params}
                                name="sampleDelivery"
                                onChange={handleInputChange} />
                        }
                    />
                </div>
                <div className={styles['txtfield-wrapper']}>
                    <span>MASS ORDER DELIVERY</span>
                    <Autocomplete
                        id="massdelivery"
                        size='small'
                        options={massOrderOptions}
                        sx={inputStyle}
                        freeSolo
                        value={formData.massOrderDelivery}
                        onChange={massOrderAutoCompleteHandler}
                        loading={optionLoading}
                        renderInput={(params) =>
                            <TextField
                                {...params}
                                name='massOrderDelivery'
                                onChange={handleInputChange} />
                        }
                    />
                </div>
            </div>

            <div className={styles['payment-wrapper']}>
                <div className={styles['txtfield-wrapper']}>
                    <span>Payment Term</span>
                    <Autocomplete
                        id="paymentterm"
                        size='small'
                        options={paymentTermOptions}
                        sx={inputStyle}
                        freeSolo
                        value={formData.paymentTerm}
                        onChange={paymentTermAutoCompleteHandler}
                        renderInput={(params) =>
                            <TextField
                                {...params}
                                name='paymentTerm'
                                onChange={handleInputChange} />
                        }
                    />
                </div>
                <div style={{ display: 'flex' }}>
                    <div className={styles['txtfield-wrapper']}>
                        <span>Payment Mode</span>
                        <Autocomplete
                            id="paymentmode"
                            size='small'
                            options={paymentModeOptions}
                            sx={inputStyle}
                            freeSolo
                            value={formData.paymentMode}
                            onChange={paymentModeAutoCompleteHandler}
                            renderInput={(params) =>
                                <TextField
                                    {...params}
                                    name='paymentMode'
                                    onChange={handleInputChange} />
                            }
                        />
                    </div>
                    <div className={styles['txtfield-wrapper']}>
                        <span>Payment Due</span>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                sx={{
                                    ...inputStyle, 
                                    "& .MuiInputBase-root":{
                                        ...inputStyle['& .MuiInputBase-root'],
                                        paddingRight:2
                                    }}}
                                value={formData.paymentDueDate ? dayjs(formData.paymentDueDate) : null}
                                format="DD MMM YYYY"
                                disabled={formHeader.xeroInvoiceId !== ''}
                                onAccept={paymentDueDateHandler}
                            />
                        </LocalizationProvider >
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FormOverview