import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import styles from './UserForm.module.css';

import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { AccountCircle, Lock, Save, IntegrationInstructions } from '@mui/icons-material';
import TextField from '@mui/material/TextField';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import LoadingButton from '@mui/lab/LoadingButton';
//import Button from '@mui/material/Button';

import UserInput from '../../../UI/user-input-hook';
import { useLocation } from 'react-router-dom';

import XeroIconPng from '../../../assets/icon/xero-icon.png'
import { xeroApi } from '../../../Api';
import { setAuthCode } from '../../../store/auth-actions';
import { useDispatch } from 'react-redux';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <>{children}</>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
        disableRipple: true,
        style: { color: '#444' }
    };
}

const parentStyle = { flexGrow: 1, bgcolor: 'background.paper', display: 'flex' };

const XeroOrganisation = (props) => {
    //removeTenantHandler
    const { org, isSelected, user, onSelect } = props;

    return (
        <div 
            className={styles['org-data-wrapper']} 
            style={{ backgroundColor: isSelected ? '#E2F0D9' : null }}
            onClick={() => onSelect(org)}>

            <div className={styles.org}>
                <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
                    <h3 style={{ margin: 0 }}>{org.tenantType}</h3>
                    {
                        !!user.xero.activeTenant && user.xero.activeTenant.id === org.id &&
                        <span className={styles['status-chip']}>ACTIVE</span>
                    }
                </div>
                <span style={{ fontSize: '1.2rem' }}>
                    {org.tenantName}
                </span>
                <span className={styles['org-time']}>
                    Connected since {dayjs(`${org.updatedDateUtc}Z`).format('DD/MM/YYYY hh:mm a')}
                </span>
            </div>
            {/* <div style={{ flex: '0 0 auto' }}>
                <Button
                    variant="contained"
                    color="error"
                    size="small"
                    onClick={() => removeTenantHandler(org.id)}>
                    remove
                </Button>
            </div> */}
        </div>
    )
}

const UserForm = (props) => {

    const dispatch = useDispatch();

    const { user, onSave, viewOnly, isUpdating } = props;

    const location = useLocation();

    //tab value
    const [value, setValue] = useState(0);
    const handleChange = (_, newValue) => { setValue(newValue); };

    //Form values    
    const [firstName, setFirstName] = useState(user.firstname);
    const firstNameInputHasError = firstName?.trim() === ''

    const [lastName, setLastName] = useState(user.lastname);

    const [displayName, setDisplayName] = useState(user.displayname);
    const displayNameInputHasError = displayName?.trim() === ''

    const [email, setEmail] = useState(user.email);
    const emailInputHasError = email?.trim() === '' || !email?.includes('@')

    const [contact, setContact] = useState(user.contact);

    const [selectedRole, setSelectedRole] = useState(user.role);

    const [selectedXeroTenant, setSelectedXeroTenant] = useState(null);

    const {
        value: enteredPassword,
        isValid: passwordIsValid,
        hasError: passwordInputHasError,
        valueChangeHandler: passwordChangedHandler,
        inputBlurHandler: passwordBlurHandler } = UserInput(value => value !== '' && value.length >= 7);

    const {
        value: enteredCfmPassword,
        isValid: cfmPasswordIsValid,
        hasError: cfmPasswordInputHasError,
        valueChangeHandler: cfmPasswordChangedHandler,
        inputBlurHandler: cfmPasswordBlurHandler } = UserInput(value => value !== '' && value === enteredPassword);

    const _passwordInputHasError = () => {
        if (enteredPassword === '' && enteredCfmPassword === '') {
            return false;
        }

        return passwordInputHasError
    }

    const _cfmPasswordInputHasError = () => {
        if (enteredPassword === '' && enteredCfmPassword === '') {
            return false;
        }

        return cfmPasswordInputHasError
    }

    const validPassword = () => {
        if (enteredPassword === '' && enteredCfmPassword === '') {
            return true;
        }

        return passwordIsValid && cfmPasswordIsValid;
    }

    const passwordErrorText = () => {
        if (enteredPassword === '' && enteredCfmPassword === '') {
            return null;
        }

        if (!_passwordInputHasError()) {
            return null;
        }

        return enteredPassword === '' ?
            'Password cannot be empty' :
            'password must have more than 6 chars'
    }

    const cfmPasswordErrorText = () => {

        if (enteredPassword === '' && enteredCfmPassword === '') {
            return null;
        }

        if (!_cfmPasswordInputHasError()) {
            return null;
        }

        return enteredCfmPassword === '' ?
            'Password cannot be empty' :
            'password does not match'
    }

    const formIsValid = !firstNameInputHasError &&
        !displayNameInputHasError &&
        !emailInputHasError &&
        validPassword()

    useEffect(() => {
        if(location.hash === '#xero'){
            setValue(2)//xero tab
        }
    },[location])

    useEffect(() => {
        if (user.xero) {
            setSelectedXeroTenant(user.xero.activeTenant);
        }
    },[user?.xero])

    const handleSaveHandler = () => {
        var update = {
            firstname: firstName,
            lastname: lastName,
            displayname: displayName,
            contact,
            email,
        }

        if(user.xero){
            update.xero = {...user.xero}
            update.xero.activeTenant = {...selectedXeroTenant}
        }

        if (!_cfmPasswordInputHasError() && enteredPassword !== '') {
            update['password'] = enteredPassword;
        }
        
        onSave(update)
    }

    const connectXeroHandler = () => {
        xeroApi.post('/get-secure-token',{})
            .then(res => {
                dispatch(setAuthCode(res.data.code))
                window.location.replace(`${xeroApi.defaults.baseURL}/gateway-connection?authCode=${res.data.code}`); 
            })
    }

    // const removeTenantHandler = (id) => {
    //     console.log('remove handler')
    // }

    const renderXeroButton = () => {
        return <div className={styles['xero-button']} onClick={connectXeroHandler}>
            <img src={XeroIconPng} alt="xero-icon" />
            Sign In with Xero
        </div>
    }

    const renderXeroSignInPrompt = () => {
        return (
            <>
                <span>
                    Connect to your xero account for better user experience. With integrated
                    xero, you can search the contacts in your order form and populate the fields,
                    improving your productivity.
                </span>
                <span
                    style={{color:'#404040',fontStyle:'italic',marginTop:16,fontSize:'0.8rem'}}>
                    (Please ensure your browser popup is not prevented, you will be 
                    redirected to internal page to connect to Xero securely.)
                </span>
                <span style={{marginTop:16,fontSize:'0.8rem',color:'red',fontWeight:'bold'}}>
                        Please ensure the email address use to login Xero is the same as 
                        registered email in TREA Order Form System.
                </span>

                {renderXeroButton()}
            </>
        )
    }

    const selectTenant = (tenant) => {
        setSelectedXeroTenant(tenant);
    }

    const renderXeroFields = () => {
        return (
            <div className={styles['xero-wrapper']}>
                <div className={styles['xero-body']}>
                    <span style={{ color: '#0FB5EB' }}>You are connected to Xero</span>
                    <span>
                        If you connected to more than one organisation, ensure the correct
                        organisation is selected as data resides within are different.
                    </span>

                    {
                        user.xero.allTenants.map(tenant => 
                            <XeroOrganisation
                                key={tenant.id}
                                onSelect={selectTenant}
                                org={tenant}
                                user={user}
                                //removeTenantHandler={removeTenantHandler}
                                isSelected={!!selectedXeroTenant && selectedXeroTenant.id === tenant.id} 
                            />)
                    }
                </div>

                <div style={{ flex: '0 0 auto' }}>
                    {renderXeroButton()}
                </div>
            </div>
        )
    }

    return (
        <>
            <Box sx={parentStyle}>
                <Tabs
                    orientation="vertical"
                    value={value}
                    onChange={handleChange}
                    aria-label="profile tabs"
                    sx={{ borderRight: 1, borderColor: 'divider', flex: '0 0 auto' }}
                >
                    <Tab
                        label="Account"

                        icon={<AccountCircle fontSize='small' />}
                        iconPosition="start"
                        {...a11yProps(0)} />

                    {!viewOnly && <Tab
                        label="Password"
                        icon={<Lock fontSize='small' />}
                        iconPosition="start"
                        {...a11yProps(1)} />}

                    {
                        location.pathname === '/profile' &&
                        <Tab
                            label="Xero"
                            icon={<IntegrationInstructions fontSize='small' />}
                            iconPosition="start"
                            {...a11yProps(2)} />
                    }

                </Tabs>

                <TabPanel value={value} index={0} className={value === 0 ? styles['tab-wrapper'] : null}>
                    <h2>General Info</h2>
                    <div className={styles['input-row']}>
                        <div className={styles['input-group']}>
                            FIRST NAME
                            <TextField
                                error={firstNameInputHasError}
                                id="firstname"
                                variant="outlined"
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                                helperText={firstNameInputHasError ? 'Cannot be empty' : null} />
                        </div>
                        <div className={styles['input-group']}>
                            LAST NAME
                            <TextField
                                id="lastname"
                                variant="outlined"
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)} />
                        </div>
                    </div>
                    <div className={styles['input-row']}>
                        <div className={styles['input-group']}>
                            DISPLAY NAME
                            <TextField
                                error={displayNameInputHasError}
                                id="displayname"
                                variant="outlined"
                                value={displayName}
                                onChange={(e) => setDisplayName(e.target.value)}
                                helperText={displayNameInputHasError ? 'Cannot be empty' : null} />
                        </div>
                        <div className={styles['input-group']}>
                            CONTACT
                            <TextField
                                id="contact"
                                variant="outlined"
                                value={contact}
                                onChange={(e) => setContact(e.target.value)} />
                        </div>
                    </div>
                    <div className={styles['input-row']}>
                        <div className={styles['input-group']}>
                            EMAIL
                            <TextField
                                error={emailInputHasError}
                                id="email"
                                variant="outlined"
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                helperText={emailInputHasError ? 'Cannot be empty' : null} />
                        </div>
                    </div>
                    {
                        (user.role !== 'Owner' && user.role !== 'SysInternal') &&
                        <div className={styles['input-row']}>
                            <div className={styles['input-group']}>
                                User Type
                                <RadioGroup
                                    aria-labelledby="role-buttons-group"
                                    name="role-buttons-group"
                                    value={selectedRole}
                                    onChange={(e) => setSelectedRole(e.target.value)}
                                >
                                    <FormControlLabel
                                        value="Manager"
                                        control={<Radio size="small" />}
                                        label={<span>Manager</span>} />

                                    <FormControlLabel
                                        value="Standard"
                                        control={<Radio size="small" />}
                                        label={<span>Standard</span>} />
                                </RadioGroup>
                            </div>
                        </div>
                    }

                </TabPanel>

                <TabPanel value={value} index={1} className={value === 1 ? styles['tab-wrapper'] : null}>
                    <h2>Change Password</h2>
                    <div className={styles['input-row']}>
                        <div className={styles['input-group']}>
                            PASSWORD
                            <TextField
                                error={_passwordInputHasError()}
                                id="password"
                                variant="outlined"
                                type="password"
                                value={enteredPassword}
                                onChange={passwordChangedHandler}
                                onBlur={passwordBlurHandler}
                                helperText={passwordErrorText()} />
                        </div>
                        <div className={styles['input-group']}>
                            CONFIRM PASSWORD
                            <TextField
                                error={_cfmPasswordInputHasError()}
                                id="cfmpassword"
                                variant="outlined"
                                type="password"
                                value={enteredCfmPassword}
                                onChange={cfmPasswordChangedHandler}
                                onBlur={cfmPasswordBlurHandler}
                                helperText={cfmPasswordErrorText()} />
                        </div>
                    </div>
                </TabPanel>

                <TabPanel value={value} index={2} className={styles['tab-wrapper']}>
                    <h2>Integration</h2>
                    {
                        !user.xero && renderXeroSignInPrompt()
                    }
                    {
                        !!user.xero && renderXeroFields()
                    }

                </TabPanel>
            </Box>

            {!viewOnly && <div className={styles['action-wrapper']}>
                <LoadingButton
                    color="success"
                    onClick={handleSaveHandler}
                    loading={isUpdating}
                    loadingPosition="start"
                    startIcon={<Save />}
                    variant="contained"
                    disabled={!formIsValid}
                >
                    <span>Save</span>
                </LoadingButton>
            </div>}
        </>
    )
}

export default UserForm