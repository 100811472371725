import React from 'react';

import styles from './ErrorPage.module.css';

const ErrorPage = (props) => {

    const statuscode = props.statusCode || '404';

    return (
        <div className={styles.main}>
            <div className={styles['main-content']}>
                <h1 className={styles['status-code']}>{statuscode}</h1>
                <h1 style={{marginBottom:'16px'}}>Error Encountered!</h1>
                { props.children }
            </div>
        </div>
    )
}

export default ErrorPage