import { useEffect, useState } from "react"
import { 
    Navigate, 
    useLocation 
} from "react-router-dom"
import { getAuthUserToken } from "../../../store/auth-actions";


const ProtectedRoute = ({user, children}) => {
    
    const location = useLocation();
    const [validUser, setValidUser] = useState(!!getAuthUserToken())
    
    useEffect(() => {
        setValidUser(!!getAuthUserToken())
    },[location])

    if(!user || !validUser){
        return <Navigate to="/login" replace />
    }

    return children;
}

export default ProtectedRoute