

import { userActions } from "./user-slice"
import { updateAuthUser } from "./auth-actions"
import { userApiV2 } from "../Api"

export const getUser = (userId) => {
    return async (dispatch) => {
        dispatch(userActions.Request_User());

        userApiV2.get(`/${userId}`).then(response => {
            dispatch(userActions.Request_User_Response({ data: response.data, err: null }))
        }).catch(err => {
            dispatch(userActions.Request_User_Response({ data: null, err: err.response.data.message }))
        })
    }
}
//?fields=_id,firstname,lastname
export const getUsers = (fields = []) => {
    return async (dispatch) => {

        dispatch(userActions.Request_Users());

        const params = fields.length > 0 ? `?fields=${fields.join(',')}` : '';

        userApiV2.get(params)
            .then(response => {
                dispatch(userActions.Request_Users_Response({ data: response.data, err: null }))
            }).catch(err => {
                console.log(err)
                dispatch(userActions.Request_Users_Response({ data: null, err: err.response.data.message }))
            })
    }
}

export const updateUser = (userId, fields, isUpdateSelf = false) => {
    return async (dispatch) => {

        dispatch(userActions.Update_User());

        userApiV2.patch(`/${userId}`, { ...fields })
            .then(response => {
                dispatch(userActions.Update_User_Response({ data: response.data, err: null }))

                if (isUpdateSelf) {
                    dispatch(updateAuthUser(response.data))
                }
            }).catch(err => {
                console.log(err)
                dispatch(userActions.Update_User_Response({ data: null, err: err.response.data.message }))
            })
    }
}

export const addUser = (user) => {
    return async (dispatch) => {

        dispatch(userActions.Request_Add_User());

        userApiV2.post('', { ...user })
            .then(response => {
                dispatch(userActions.Request_Add_User_Response({ data: response.data, err: null }))
            }).catch(err => {
                console.log(err)
                dispatch(userActions.Request_Add_User_Response({ data: null, err: err.response.data.message }))
            })
    }
}

export const resetAddUserState = () => {
    return (dispatch) => {
        dispatch(userActions.Request_Add_User_Response({ data: null, err: null }))
    }
}

export const deleteUser = (user) => {
    return async (dispatch) => {

        dispatch(userActions.Request_Delete_User());

        userApiV2.delete(`/${user._id}`)
            .then(response => {
                dispatch(userActions.Request_Delete_User_Response({ data: response.data, err: null }))
            }).catch(err => {
                console.log(err)
                dispatch(userActions.Request_Delete_User_Response({ data: null, err: err.response.data.message }))
            })
    }
}